import React from 'react'
import { trans } from '../../../../../Utils'

function CurrenciesIcon(props) {
  return (
    <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>
      <div className='icon-lang-desc'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.2" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
            <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#8E8E8E" />
          </g>
        </svg>
        <div className='icon-lang-desc-bu hst'>{trans({ id: "currencies", defaultMessage: "Currencies" })}</div>
      </div>

      <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">tt
        <g id="Mask_Group_107" data-name="Mask Group 107">
          <g id="different-currencies">
            <g id="Layer_1_129_">
              <g id="Group_4763" data-name="Group 4763">
                <g id="Group_4758" data-name="Group 4758">
                  <path id="Path_16078" data-name="Path 16078" d="M6.61,5.049a4.518,4.518,0,0,1,.8-2.185,3.647,3.647,0,1,0-4.321,4.35A5.468,5.468,0,0,1,6.61,5.049ZM2.393,4.516a.231.231,0,0,1,.235-.235h.748V4.106l-.1-.175H2.628a.235.235,0,0,1,0-.47H3L2.2,2.152A.528.528,0,0,1,2.116,1.9a.468.468,0,0,1,.518-.44.461.461,0,0,1,.41.283l.808,1.538L4.66,1.748a.461.461,0,0,1,.41-.283.468.468,0,0,1,.519.44.529.529,0,0,1-.084.247l-.8,1.309h.368a.235.235,0,0,1,0,.47H4.431l-.1.175V4.28h.748a.235.235,0,0,1,0,.47H4.328v.6a.445.445,0,0,1-.476.483.445.445,0,0,1-.476-.483v-.6H2.628A.231.231,0,0,1,2.393,4.516Z" fill="#8E8E8E" />
                </g>
                <g id="Group_4759" data-name="Group 4759">
                  <path id="Path_16079" data-name="Path 16079" d="M11.148,1.824A3.651,3.651,0,0,0,7.538,4.972a5.438,5.438,0,0,1,1.971.385.255.255,0,0,1,.254-.152H9.89a1.123,1.123,0,0,1-.181-.645,1.371,1.371,0,0,1,1.513-1.272c1.056,0,1.568.567,1.568,1.037a.407.407,0,0,1-.453.422c-.53,0-.205-.736-1.019-.736a.631.631,0,0,0-.693.6,1.323,1.323,0,0,0,.2.591h.657c.247,0,.374.091.374.289s-.127.289-.374.289h-.477a.383.383,0,0,1,.031.145.778.778,0,0,1-.062.288,5.5,5.5,0,0,1,.552.532,2.382,2.382,0,0,0,.493.073c.1,0,.35-.066.446-.066a.349.349,0,0,1,.35.386c0,.34-.3.488-.618.508a5.518,5.518,0,0,1,.509,1.114,3.644,3.644,0,0,0-1.559-6.94Z" fill="#8E8E8E" />
                </g>
                <g id="Group_4760" data-name="Group 4760">
                  <path id="Path_16080" data-name="Path 16080" d="M7.742,10.875v1.492c.452-.03.927-.241.927-.739C8.669,11.116,8.148,10.966,7.742,10.875Z" fill="#8E8E8E" />
                </g>
                <g id="Group_4761" data-name="Group 4761">
                  <path id="Path_16081" data-name="Path 16081" d="M6.445,9.089c0,.378.279.6.844.709V8.448C6.776,8.464,6.445,8.765,6.445,9.089Z" fill="#8E8E8E" />
                </g>
                <g id="Group_4762" data-name="Group 4762">
                  <path id="Path_16082" data-name="Path 16082" d="M7.5,5.882a4.559,4.559,0,1,0,4.56,4.559A4.565,4.565,0,0,0,7.5,5.882Zm.242,7.255V13.6a.228.228,0,1,1-.453,0v-.468c-1.273-.03-1.907-.791-1.907-1.388a.434.434,0,0,1,.467-.474c.844,0,.188,1.04,1.44,1.093V10.792C6.172,10.589,5.5,10.1,5.5,9.262A1.649,1.649,0,0,1,7.289,7.679v-.4a.228.228,0,1,1,.453,0v.4c.587.016,1.793.384,1.793,1.123a.453.453,0,0,1-.475.467c-.49,0-.483-.806-1.318-.821V9.88c1,.211,1.876.5,1.876,1.667C9.618,12.557,8.864,13.069,7.742,13.137Z" fill="#8E8E8E" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>


    </div>
  )
}

export default CurrenciesIcon