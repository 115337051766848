const initialState = {
    ballets: [],
    mainStatuses: [],
    countries: []
};

export const shppingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BALLETS_REDUCER": return {
            ...state,
            ballets: action.payload
        };
        case "GET_BALLETS_STATUS_REDUCER": return {
            ...state,
            mainStatuses: action.payload
        };
        case "GET_BALLETS_COUNTRIES_REDUCER": return {
            ...state,
            countries: action.payload
        }
        default: return { ...state };
    }
}