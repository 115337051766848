import React, { useState } from 'react'
import BulkIcon from "./svg/bulk_icon"
import PackageCart from "./svg/package_cart"
import SmallCountryIcon from "./svg/small_iraq_icon"
import Product from "./svg/products"
import Kilogram from "./svg/kilogram"
import Vweight from "./svg/v_weight"
import Time from "./svg/time"
import Order from './svg/order'

export default function Bulk(props) {
    const bulk = props.bulk
    const status = props.status
    const filter = props.filter
    const country = props.country
    const city = props.city
    const currentBulk = props.currentBulk
    const filters = props.filters
    const setCurrentBulk = (e) => props.setCurrentBulk(e)
    const [openDetail, setOpenDetail] = useState(false)
    const [openOrders, setOpenOrders] = useState(false)
    const getTime = (t) => {
        let diff = parseFloat((new Date().getTime() - new Date(t).getTime()) / (1000 * 3600)).toFixed(1)
        if (diff > 24) {
            return (`${(diff / 24).toFixed(1)} d`)
        } else {
            if (diff > 1) {
                return (`${diff} h`)
            } else {
                if (diff * 60 > 1) {
                    return (`${(diff * 60).toFixed(1)} m`)
                } else {
                    return (`${(diff * 3600).toFixed(1)} s`)
                }
            }
        }
    }
    return (<div className={`${currentBulk === bulk.id ? "bulk_element_with_status_selected" : "bulk_element_with_status"}`}
        onMouseEnter={() => {
            setCurrentBulk(bulk.id)
        }}
        onMouseLeave={() => {
            if (currentBulk === bulk.id && !openDetail && !openOrders) {
                setCurrentBulk(null)
            }
        }}
    >
        <div className="bulk_element" onClick={() => {
            setCurrentBulk(bulk.id)
            if (openOrders === false) {
                setOpenDetail(true)
            } else {
                setOpenDetail(false)
            }
            setOpenOrders(!openOrders)
        }} style={{ backgroundColor: filter ? filter.icons_color : "#ffffff" }}>
            <div className="bulk_element_name">
                <div className="bulk_element_name_icon">
                    <div className="bulk_icon">
                        <BulkIcon color={status ? status.icons_color : "#ffffff"} />
                    </div>
                    <div className="bulk_name">
                        {bulk.ShipmentNumber}
                    </div>
                </div>
                <div className="bulk_element_right_icon">
                    <PackageCart color1={filter ? filter.color1 : "#ffffff"} color2={filter ? filter.color2 : "#ffffff"} />
                </div>
            </div>
            <div className="bulk_element_data">
                <div className="bulk_element_country_data">
                    <div className="bulk_element_country_icon">
                        <SmallCountryIcon />
                    </div>
                    <div className="bulk_element_country_name">
                        {country ? country.region_translations[0]?.region_name : "iraq"}, {city ? city.region_translations[0]?.region_name : "Erbil"}
                    </div>
                </div>
                <div className="bulk_element_detail_data">
                    <Product icons_color="#A7EDCB" />
                    <div className="products_order_count">{bulk.products_count}/{bulk.orders.length}</div>
                    <Kilogram />
                    <div className="bulk_element_weight">{bulk.weight}</div>
                    <Vweight />
                    <div className="bulk_element_weight">{bulk.v_weight}</div>
                    <Time color="#8E8E8E" />
                    <div className="bulk_element_time">
                        <div className="bulk_element_time_num"> 3&nbsp;</div>
                        <div className="bulk_element_time_unit" style={{ textTransform: "lowercase" }}> h </div>
                    </div>
                </div>
            </div>
        </div>
        {(currentBulk === bulk.id) && openOrders ? <div className='bulk_orders_area'>
            {bulk.orders?.map((order, index) => <div key={index} className='bulk_orders_area_element'>
                <div className='bulk_order_icon_name'>
                    <div className='bulk_order_icon'> <Order /></div>
                    <div className='bulk_order_name'>{order.ShipmentNumber}</div>
                </div>
                <div className='bulk_order_details'>
                    <div>{order.products_count}</div>
                    <div>{order.weight}</div>
                    <div>{order.v_weight}</div>
                    <div className='bulk_order_time'>1&nbsp;d</div>
                </div>
            </div>)}
        </div> : <></>}
        {currentBulk === bulk.id ? (!openDetail ? <div className={`${openOrders ? "status_area_element_with_order" : "status_area_element"}`}
            style={{ backgroundColor: filter ? filter.details_color : "#ffffff" }}
            onClick={() => setOpenDetail(true)}>
            <div className="status_area_element_name">
                <div className="status_area_element_name_icon">
                    <PackageCart color1={filter ? filter.color1 : "#ffffff"} color2={filter ? filter.color2 : "#ffffff"} />
                </div>
                <div className="status_area_element_name_name">
                    {bulk.tracking_details[bulk.tracking_details.length - 1].TrackingNotes}
                </div>
            </div>
            <div className="status_area_element_time">
                <div className="status_area_element_time_time">
                    {getTime(bulk.tracking_details[0].start_pallet_status_time)}
                </div>
                <div className="status_area_element_time_icon">
                    <Time color="#5D5D5D" />
                </div>
            </div>
        </div> : <div className={`${openOrders ? "status_area_all_in_with_order" : "status_area_all_in"}`} onClick={() => setOpenDetail(false)} style={{ backgroundColor: filter ? filter.details_color : "GrayText" }}>
            {bulk.tracking_details?.map((status, index) => <div key={index} className='status_area_all_element'>
                <div className='status_area_all_element_row'>
                    <div className="status_area_element_name_row">
                        <div className="status_area_element_name_icon_row">
                            <PackageCart color1={filters.filter(one => one.id === status.pallet_status_id)[0]?.color1} color2={filters.filter(one => one.id === status.pallet_status_id)[0]?.color2} />
                        </div>
                        <div className="status_area_element_name_name_row">
                            <div className="status_area_element_name_name_row_statusname">
                                {status.last_pallet_status.translations[0].name}&nbsp;
                            </div>
                            <div className="status_area_element_name_name_row_username">
                                "{status.last_pallet_status_user.username}"
                            </div>
                        </div>
                    </div>
                    <div className="status_area_element_time_row">
                        <div className="status_area_element_time_time_row">
                            {getTime(status.start_pallet_status_time)}
                        </div>
                        <div className="status_area_element_time_icon_row">
                            <Time color={bulk.tracking_details.length === index + 1 ? "#8E8E8E" : "#C1C1C1"} />
                        </div>
                    </div>
                </div>
                <div className='status_area_all_element_row'>
                    <div className="status_area_element_name_row">
                        <div className="status_area_element_name_icon_row"></div>
                        <div className="status_area_element_name_name_row_current">
                            {status.TrackingNotes}
                        </div>
                    </div>
                    <div className="status_area_element_time_row">
                        <div className="status_area_element_time_time_row">
                            {status.time_in_current_status}{getTime(status.start_pallet_status_time)}
                        </div>
                        <div className="status_area_element_time_icon_row">
                            <Time color={bulk.tracking_details.length === index + 1 ? "#5D5D5D" : "#C1C1C1"} />
                        </div>
                    </div>
                </div>
            </div>)}
        </div>) : <></>}
    </div>
    )
}