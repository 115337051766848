import React, { useState } from 'react'
import Package from "./svg/package"
import SmallPackage from './svg/small_package'
import Product from './svg/products'

export default function MainStatuses(props) {
    const statuses = props.statuses
    const active = props.activeStatus
    const setActive = (value) => {
        props.setActiveStatus(value)
    }
    return (<div className="shipment_main_statuses">
        {statuses.map((status, index) => <div key={index} id={`status_main_${index}`} className={active === null ? 'status_normal' : (active === status.id ? 'status_active' : 'status_not_active')} onClick={() => {
            if (active === status.id) {
                setActive(null)
            } else {
                setActive(status.id)
            }
        }} style={{ backgroundColor: status.color1 }}>
            <div className='status_icon'>
                <Package icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
            </div>
            {(active === null || active === status.id) ? <div className='status_info'>
                <div className='status_info_name'>{status.translations[0].name}</div>
                <div className='status_info_info'>
                    <div className='status_info_info_packages'>
                        <SmallPackage icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
                        <div className='status_info_info_packages_num'>
                            {status.pallets_count}
                        </div>
                    </div>
                    <div className='status_info_info_products'>
                        <Product icons_color={status.icons_color} />
                        <div className='status_info_info_products_num'>
                            {status.orders_count}
                        </div>
                    </div>
                </div>
            </div> : <></>}
        </div>)}
    </div>)
}