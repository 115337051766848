import React from 'react'
import NavItem from './NavItem'
import plus from "../Routes/icons/svgs/plus.svg"
import CanCall from "../../CanCall";
import { trans } from '../../../Utils'

function VerticalLayout(props) {
    const { setActiveLink, ActiveLink, main, AddLayout, viewedList } = props
    return (
        <div className={`vertical-layout active-menu ${props.foc ? " mt-20" : ""}`}>
            {props.option.map((child, index) => (
                <div key={index} >
                    <div className='parent-info minfo'>
                        {<div className='parent-icon minfo'>{child.icon &&
                            <img alt='no' width={"20px"} height="20px" src={child.icon} />}</div>}
                        <div className='parent-name'>{trans({ id: child.key, defaultMessage: child.key })}</div>
                        {!props.main && <CanCall action='USER_ADD_CATEGORY' id='addCategoryBtn'>
                            <div className='plus-con'>{<img alt='plus' width={"20px"} height="20px" src={plus} />}</div>
                        </CanCall>}
                    </div>
                    <div className='parent-childs'>
                        {child.childreen.map((childs, ind) => (
                            <NavItem key={ind} AddLayout={(e, level, element) => AddLayout(e, 0, element)} viewedList={viewedList}
                                main={main} ActiveLink={ActiveLink} setActiveLink={(e) => setActiveLink(e)}
                                child={childs} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default VerticalLayout