import React from 'react'
import edit from "../Routes/icons/svgs/edit.svg"
import CanCall from "../../CanCall";

function MainMenuItem(props) {

    const { child, viewedList, AddLayout, level, EnableEdit } = props
    return (<>
        {
            <div
                className={`parent-info  ${!EnableEdit && "enable_edit"} ${viewedList.filter((m) => m.name === child.name).length > 0 && "rx10"}`}
                onClick={(e) => {
                    if (!EnableEdit) AddLayout({
                        name: child.name,
                        icon: child.icon,
                        childreen: child.childreen
                    }, level, e)
                }}>
                <div
                    className={`parent-icon ${EnableEdit && EnableEdit.name !== child.name && "minfos"} ${(viewedList.filter((k, i) => i === level).length === 0) || (viewedList.filter((m) => m.name === child.name).length === 0 && "minfos")}`}>
                    <svg data-src={child.icon} width={"20"} height="20"></svg></div>
                <div
                    className={`parent-name ${EnableEdit && EnableEdit.name !== child.name && "minfos"} ${(viewedList.filter((k, i) => i === level).length === 0) || (viewedList.filter((m) => m.name === child.name).length === 0 && "minfos")}`}>{child.name}</div>
                <CanCall action='USER_EDIT_CATEGORY' id='editCategoryBtn'>
                    <div className='plus-con edit_icon' onClick={() => props.setEdit(true)}><img
                        className='disable_click' alt='edit' src={edit} /></div>
                </CanCall>
            </div>
        }
    </>)
}

export default MainMenuItem