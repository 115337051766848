import React, { useState } from 'react'
import { TopNav } from "../shared/index"
import Shipment from "../icons/svgs/shipment.svg"
import { trans } from "../../../../Utils"
import { useSelector, useDispatch } from "react-redux"
import "./ship.css"
import "./ship2.css"
import "./bulks.css"
import Filter from './filter'
import FilterCount from './filter_count'
import MainStatuses from './main_statuses'
import PackageFilter from './package_filter'
import PackageFilterExpand from './package_filter_expand'
import BulkList from './bulks_list'
import BulkExpandedList from './bulk_expanded'
import { useEffect } from 'react'

export default function Shipments(props) {
    const dispatch = useDispatch()
    const [lang, setLang] = useState(null)
    useEffect(() => {
        dispatch({ type: "GET_BALLETS_SAGA" })
        dispatch({ type: "GET_BALLETS_STATUS_SAGA" })
        dispatch({ type: "GET_BALLETS_COUNTRIES_SAGA" })
    }, [lang])
    const [activeStatus, setActiveStatus] = useState(null)
    const [filters, setFilters] = useState([
        {
            id: 0,
            name: "Iraq",
            icon: "iraq",
            total: 10,
            cities: [
                {
                    id: 0,
                    name: "Erbil",
                    count: 5
                }
            ]
        },
        {
            id: 1,
            name: "Lebanon",
            icon: "lebanon",
            total: 10,
            cities: [
                {
                    id: 0,
                    name: "Beirut",
                    count: 5
                }]
        }
    ])
    const [fakeFilterSelected, setFakeFilterSelected] = useState(null)
    const translated_lang = useSelector((state) => state.auth.lang)
    const [currentBulk, setCurrentBulk] = useState(null)
    const countries = useSelector(state => state.shipping.countries)
    const bulks = useSelector(state => state.shipping.ballets)
    const mainStatuses = useSelector(state => state.shipping.mainStatuses)

    const getLang = () => {
        if (lang) {
            return (lang)
        }
        else {
            return translated_lang
        }
    }
    return (<div className={`languages-page ${props.active && "Active-page"}`}>
        <TopNav
            table_icon={Shipment}
            table_name={trans({ id: "shipments", defaultMessage: "Shipments" })}
            top_name_clk={() => { }}
            setLang={(e) => setLang(e)}
            disableAdd={true}
            lang={getLang()}
        />
        <Filter countries={countries} filters={filters} setFilters={(value) => setFilters(value)} />
        <FilterCount filters={filters} />
        <MainStatuses statuses={mainStatuses} activeStatus={activeStatus} setActiveStatus={(e) => setActiveStatus(e)} />
        {activeStatus === null ?
            <>
                <PackageFilter data={mainStatuses.length > 0 ? mainStatuses[0].sub_statuses : []} setFilter={(e) => setFakeFilterSelected(e)} selected={fakeFilterSelected} />
                <BulkList currentBulk={currentBulk} setCurrentBulk={(e) => setCurrentBulk(e)} data={bulks} countries={countries} mainStatuses={mainStatuses} selectedFilter={fakeFilterSelected} />
            </> : (activeStatus === mainStatuses[0].id ?
                <>
                    <PackageFilterExpand data={mainStatuses.length > 0 ? mainStatuses[0].sub_statuses : []} />
                    <BulkExpandedList currentBulk={currentBulk} setCurrentBulk={(e) => setCurrentBulk(e)} data={bulks} countries={countries} mainStatuses={mainStatuses} fakeFilter={mainStatuses.length > 0 ? mainStatuses[0].sub_statuses : []} />
                </> :
                <></>)
        }
    </div>)
}