import { all, fork, takeEvery, put } from "@redux-saga/core/effects"
import store from "../store"
import { upload } from '../../uploadService'
import AxiosInstance from "../../helpers/axiosInstance"

function* getBallets(action) {
    try {
        let ballets = yield AxiosInstance.post("/api/v1/pallets/index");
        yield put({ type: "GET_BALLETS_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBallets() {
    yield takeEvery("GET_BALLETS_SAGA", getBallets);
}

function* getBalletsStatus(action) {
    try {
        let ballets = yield AxiosInstance.get("/api/v1/pallet_statuses/main_statuses");
        yield put({ type: "GET_BALLETS_STATUS_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBalletsStatus() {
    yield takeEvery("GET_BALLETS_STATUS_SAGA", getBalletsStatus);
}

function* getBalletsCountries(action) {
    try {
        let ballets = yield AxiosInstance.get("/api/v1/regions/get_countries_with_cities");
        yield put({ type: "GET_BALLETS_COUNTRIES_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBalletsCountries() {
    yield takeEvery("GET_BALLETS_COUNTRIES_SAGA", getBalletsCountries);
}

export function* ShippingSaga() {
    yield all([
        fork(watchGetBallets),
        fork(watchGetBalletsStatus),
        fork(watchGetBalletsCountries)
    ]);
}