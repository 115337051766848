import Filter from "./svg/filter";

export default function FilterCount(props) {
    const data = props.filters

    const getTotal = (items) => {
        let sum = 0
        items.forEach(element => {
            sum += element.total
        });
        return sum
    }

    return (<div className="filter_count_area">
        <div className="filter_icon">
            <Filter />
        </div>
        <div className="filter_count_area_total">{getTotal(data)}</div>
        {data.map((counrty, index) => <div key={index} className="country_filter_group">
            {counrty.name} {` (${counrty.total}),`}
            {counrty.cities.map((city, cindex) => <div className="country_filter_group_city">
                <div className="city_name">&nbsp;{city.name} </div>
                <div className="city_count">&nbsp;{`${city.count}`}</div>
            </div>)}
            {(index !== data.length - 1) && <>&nbsp;/&nbsp;</>}
        </div>)}
    </div>)
}