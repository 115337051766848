import React from 'react'
import { useSelector } from 'react-redux'


function DisplayView(props) {
  const employee = useSelector(state => state.employee.employee)

  return (
    <div onClick={() => { props.changeView() }} className='display-view'>
      {props.view ? <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

        <g id="Mask_Group_304" data-name="Mask Group 304">
          <g id="grid-3" transform="translate(-0.682 -0.682)">
            <rect id="Rectangle_4971" data-name="Rectangle 4971" width="6.818" height="6.818" rx="2.045" transform="translate(0.682 0.682)" fill="#8e8e8e" />
            <rect id="Rectangle_4972" data-name="Rectangle 4972" width="6.818" height="6.818" rx="2.045" transform="translate(8.864 0.682)" fill="#8e8e8e" />
            <rect id="Rectangle_4973" data-name="Rectangle 4973" width="6.818" height="6.818" rx="2.045" transform="translate(0.682 8.864)" fill="#8e8e8e" />
            <rect id="Rectangle_4974" data-name="Rectangle 4974" width="6.818" height="6.818" rx="2.045" transform="translate(8.864 8.864)" fill="#8e8e8e" />
          </g>
        </g>
      </svg> :
        <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

          <g id="Mask_Group_305" data-name="Mask Group 305">
            <g id="Task_view" data-name="Task view" transform="translate(-1.071 -1.072)">
              <path id="Path_21326" data-name="Path 21326" d="M9.107,1.071H3.75a.536.536,0,0,0-.536.536V4.821a.536.536,0,0,0,.536.536H9.107a.536.536,0,0,0,.536-.536V1.607a.536.536,0,0,0-.536-.536Z" fill="#8e8e8e" />
              <path id="Path_21327" data-name="Path 21327" d="M9.107,6.429H3.75a.536.536,0,0,0-.536.536v3.214a.536.536,0,0,0,.536.536H9.107a.536.536,0,0,0,.536-.536V6.964a.536.536,0,0,0-.536-.536Z" fill="#8e8e8e" />
              <path id="Path_21328" data-name="Path 21328" d="M9.107,11.786H3.75a.536.536,0,0,0-.536.536v3.214a.536.536,0,0,0,.536.536H9.107a.536.536,0,0,0,.536-.536V12.321a.536.536,0,0,0-.536-.536Z" fill="#8e8e8e" />
              <path id="Path_21329" data-name="Path 21329" d="M13.393,8.036h-.536V1.607a.536.536,0,1,0-1.071,0V8.036H11.25a.536.536,0,1,0,0,1.071h.536v6.429a.536.536,0,1,0,1.071,0V9.107h.536a.536.536,0,0,0,0-1.071Z" fill="#8e8e8e" />
            </g>
          </g>
        </svg>


      }
      <div className="filter-op">
        <div className='filter-opt'>
          <svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsْlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">

            <g id="Mask_Group_296" data-name="Mask Group 296">
              <path id="filter-4" d="M.312,1.761H5.066a1.454,1.454,0,0,0,2.84,0H9.678a.312.312,0,1,0,0-.624H7.905a1.454,1.454,0,0,0-2.84,0H.312a.312.312,0,1,0,0,.624ZM5.656,1.45s0,0,0,0a.829.829,0,0,1,1.659,0v0a.829.829,0,0,1-1.659,0ZM9.678,8.229H7.905a1.454,1.454,0,0,0-2.84,0H.312a.312.312,0,0,0,0,.624H5.066a1.454,1.454,0,0,0,2.84,0H9.678a.312.312,0,0,0,0-.624ZM6.486,9.371a.83.83,0,0,1-.829-.827h0s0,0,0,0a.829.829,0,0,1,1.659,0v0a.83.83,0,0,1-.829.828ZM9.678,4.683H4.925a1.454,1.454,0,0,0-2.84,0H.312a.312.312,0,0,0,0,.624H2.085a1.454,1.454,0,0,0,2.84,0H9.678a.312.312,0,1,0,0-.624Zm-5.344.311s0,0,0,0A.829.829,0,0,1,2.675,5v0a.829.829,0,0,1,1.659,0Z" transform="translate(0.005 0.004)" fill="#8e8e8e" />
            </g>
          </svg>
          <span>
            {employee.length}
          </span>
        </div>
        <div className='filter-opt'>{
          props.countries?.map((a) => {
            return (<span className='country-name'>{a.region_translations?.filter((d) => d.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name} / {employee?.filter((ad) => ad.work_country_id === a.id).length}</span>)
          })

        }
          {
            props.dept.map((a) => {
              return (<span className='country-name'>{a.translations?.filter((d) => d.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name} / {employee?.filter((ad) => ad.department_id === a.id).length}</span>)
            })
          }


        </div>

      </div>
    </div>
  )
}

export default DisplayView