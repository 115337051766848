import React, { useState } from "react"
import Iraq from "./svg/iraq"
import Lebanon from "./svg/lebanon"
import Line from "./svg/line"
import Search from "./svg/search"
import CancelSearch from "./svg/cancel_search"
import InnerSearch from "./svg/innerSearch"
import InnerSearchLine from "./svg/innerSearchLine"

export default function Filter(props) {
    const countries = props.countries
    const [openSearch, setSearch] = useState(false)

    return (<div className="ship_filter_area">
        <div className="filters_icons_area">
            <div className="ship_filter_area_countries">
                {countries.map((country, index) => {
                    if (country.icon === "iraq") {
                        return (<Iraq key={index} />)
                    } else if (country.icon === "lebanon") {
                        return (<Lebanon key={index} />)
                    } else {
                        return (<Iraq key={index} />)
                    }
                })}
                <Line />
            </div>
            {!openSearch ?
                <div className="ship_filter_area_cities">
                    {countries.map((country, index) => {
                        return country.sub_regions.map((city, ind) => {
                            if (ind === 0 && index === 0) {
                                return (<div key={ind} className={`${city.selected ? "city_item_selected" : "city_item"}`}>
                                    <div className="city_name">{city.region_translations[0].region_name}</div>
                                    <div className="city_count"> {city.pallets_count}</div>
                                </div>)
                            } else {
                                return (<div className="with_line">
                                    <div className="line" />
                                    <div className={`${city.selected ? "city_item_selected" : "city_item"}`}>
                                        <div className="city_name">{city.region_translations[0].region_name}</div>
                                        <div className="city_count"> {city.pallets_count}</div>
                                    </div>
                                </div>)
                            }
                        })
                    })}
                </div> : <></>}
        </div>
        {!openSearch ?
            <div className="search_icon" onClick={() => { setSearch(true) }}>
                <Search />
            </div> : <div className="search_bar_ship">
                <div className="search_area">
                    <InnerSearch />
                    <InnerSearchLine />
                    <div className="input_placeholder_ship">
                        Search In Table, Barcode, Name, Ect
                    </div>
                </div>
                <div className="cancel_search_area" onClick={() => { setSearch(false) }}>
                    <CancelSearch />
                </div>
            </div>}
    </div>)
}