import Bulk from "./bulk"

export default function BulkList(props) {
    const list = props.data
    const selectedFilter = props.selectedFilter
    return (<div className={`${props.expanded ? 'bulk_list_parent_expanded' : 'bulk_list_parent'}`}>
        {selectedFilter === null ? list.map((bulk, index) => {
            return (<div key={index}>
                <Bulk
                    filters={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses}
                    currentBulk={props.currentBulk}
                    setCurrentBulk={(e) => props.setCurrentBulk(e)}
                    bulk={bulk}
                    status={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]}
                    filter={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses?.filter(filter => filter.id === bulk.pallet_status_id)[0]}
                    country={props.countries.filter(country => country.id === bulk.country_id)[0]}
                    city={props.countries.filter(country => country.id === bulk.country_id)[0]?.sub_regions.filter(city => city.id === bulk.city_id)[0]}
                />
            </div>)
        }) : list.map((bulk, index) => {
            if (selectedFilter === bulk.pallet_status_id)
                return (<div key={index}>
                    <Bulk
                        filters={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses}
                        currentBulk={props.currentBulk}
                        setCurrentBulk={(e) => props.setCurrentBulk(e)}
                        bulk={bulk}
                        status={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]}
                        filter={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses?.filter(filter => filter.id === bulk.pallet_status_id)[0]}
                        country={props.countries.filter(country => country.id === bulk.country_id)[0]}
                        city={props.countries.filter(country => country.id === bulk.country_id)[0]?.sub_regions.filter(city => city.id === bulk.city_id)[0]}
                    />
                </div>)
            else return <></>
        })}
    </div>)
}