export default function Iraq() {
    return (<svg id="_25x25_photo_back" data-name="25x25 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
        <g id="Mask_Group_293" data-name="Mask Group 293">
            <g id="Mask_Group_94" data-name="Mask Group 94" transform="translate(0 4.348)">
                <g id="iraq" transform="translate(0.058 -4.29)">
                    <rect id="Rectangle_4603" data-name="Rectangle 4603" width="24.885" height="5.435" transform="translate(0 9.725)" fill="#f5f5f5" />
                    <g id="Group_4684" data-name="Group 4684">
                        <path id="Path_16027" data-name="Path 16027" d="M14.975,11.35c-.045-.025-.1-.108-.082-.145s0-.035.082,0a.375.375,0,0,0,.38-.068l.1-.075.108.055a.393.393,0,0,0,.143.043.308.308,0,0,0,.148-.18c0-.057.043-.043.063.02a.3.3,0,0,1-.152.347.31.31,0,0,1-.193-.015c-.1-.04-.117-.04-.172,0a.385.385,0,0,1-.42.025" fill="#73af00" />
                        <path id="Path_16028" data-name="Path 16028" d="M15.4,10.9a.878.878,0,0,1-.1-.413c.02-.06.038-.072.093-.055.093.033.118.092.108.315-.017.165-.037.217-.093.155" fill="#73af00" />
                        <path id="Path_16029" data-name="Path 16029" d="M11.715,12.09c-.225.128-.562.15-.562.5-.5.017-1.073.04-1.6.03.142-.182,1.04-1.013,1.413-1.343a.208.208,0,0,0,.047-.233v-.02a.182.182,0,0,0-.29-.052c-.373.358-.75.688-.8.75l-.7.705a.288.288,0,0,0-.083.2h0a.29.29,0,0,0,.29.288H11.54c.11,0-.04-.42.117-.42.108,0,.117.15.108.308a.217.217,0,0,1-.218.217H8.8a.132.132,0,0,1-.132-.132V12.75a.17.17,0,0,0-.265-.14h0a.15.15,0,0,0-.067.175.5.5,0,0,1,.022.15.133.133,0,0,1-.13.133c-.295,0-.617,0-.707-.163a.192.192,0,0,0-.25-.095H7.255a.205.205,0,0,0-.09.283,1.053,1.053,0,0,1,.158.732.5.5,0,0,1-.207.218.1.1,0,0,0,.075.187.658.658,0,0,0,.515-.517h4.2a.28.28,0,0,0,.28-.28v-.9C12.122,12.25,11.83,12.273,11.715,12.09Z" fill="#73af00" />
                        <path id="Path_16030" data-name="Path 16030" d="M17.042,10.885a1.75,1.75,0,0,0,.193.108.14.14,0,0,1,.06.2.2.2,0,0,0-.027.065v1.807a.3.3,0,0,0,.25-.037.108.108,0,0,1,.17.063c.042.158.107.313.15.455a.14.14,0,0,1-.133.175H17a.108.108,0,0,1-.108-.108v-2.64a.107.107,0,0,1,.155-.087Z" fill="#73af00" />
                        <path id="Path_16031" data-name="Path 16031" d="M16.183,11.325V12.9a.125.125,0,0,1-.125.125h0a.125.125,0,0,1-.125-.125V11.63a.08.08,0,0,0-.132-.06l-.167.142a.155.155,0,0,0-.052.118V12.9a.125.125,0,0,1-.125.125h-.02a.125.125,0,0,1-.125-.125v-.75a.083.083,0,0,0-.135-.047,3.285,3.285,0,0,0-.3.277c0,.048.045,0,.067.038v.25c-.627-.117-.675.382-.653.92a.15.15,0,0,0,.15.145h1.975a.132.132,0,0,0,.133-.132v-2.45a.092.092,0,0,0-.158-.067l-.137.12a.17.17,0,0,0-.06.125Zm-1.45,1.68c-.037-.108.178-.15.2-.05s-.155.125-.2.05Z" fill="#73af00" />
                        <path id="Path_16032" data-name="Path 16032" d="M8.227,13.92a.137.137,0,0,1,.135.135.135.135,0,0,1-.135.135.138.138,0,0,1-.14-.135.14.14,0,0,1,.14-.135" fill="#73af00" />
                        <path id="Path_16033" data-name="Path 16033" d="M12.708,10.94a1.3,1.3,0,0,0,.115.063.132.132,0,0,1,.057.193.217.217,0,0,0-.033.072v1.805a.287.287,0,0,0,.25-.043.1.1,0,0,1,.158.055c.033.122.083.25.12.358a.218.218,0,0,1-.2.285h-.545a.162.162,0,0,1-.162-.163V11.08a.163.163,0,0,1,.25-.14Z" fill="#73af00" />
                    </g>
                    <path id="Path_16025" data-name="Path 16025" d="M23.022,4.29H1.865A1.865,1.865,0,0,0,0,6.155v3.57H24.885V6.155A1.865,1.865,0,0,0,23.022,4.29Z" width={25} fillOpacity="100%" fillRule="evenodd" fill="#ff4b55" />
                    <path id="Path_16026" data-name="Path 16026" d="M0,18.733A1.865,1.865,0,0,0,1.865,20.6H23.022a1.865,1.865,0,0,0,1.865-1.865V15.16H0Z" width={25} fill="#464655" />
                </g>
            </g>
        </g>
    </svg>

    )
}
