import React from 'react'
import { dashboardOptions } from './constants'
import Routes from '../Routes/Routes'
import VerticalLayout from './VerticalLayout'
import MainVertical from './MainVertical'
// import HRM from "../Routes/icons/svgs/HRM.svg"
import Footer from '../../auth/Footer'
// import { useSelector } from 'react-redux'
function Sidebar(props) {
  const { viewedList, AddLayout, foc } = props
  const { ActiveLink, setActiveLink } = props

  return (
    <div className='main-sidebar' id="sidebar">
      <Footer />

      <VerticalLayout foc={foc} AddLayout={(e, level, element) => AddLayout(e, 0, element)} viewedList={viewedList} ActiveLink={ActiveLink} setActiveLink={(e) => { setActiveLink(e); }} main={true} option={dashboardOptions} />
      {ActiveLink && ActiveLink.name === "Categories" && viewedList.map((view, index) => (
        <MainVertical key={index} index={index} level={index + 1} AddLayout={(e, level, element) => AddLayout(e, index + 1, element)} viewedList={viewedList} option={view} />
      ))}
      {ActiveLink && ActiveLink.name !== "Categories" && <Routes option={{ routes: ActiveLink.name }} />}

    </div>
  )
}

export default Sidebar