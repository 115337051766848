import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ExpandICon from '../../icons/ExpandICon'
import Empty from "../../../../../assets/svg/emptys.svg"
import CountriesChild from './CountriesChild'
import LoadingStatuse from './LoadingStatuse'
import CompainesModal from './CountriesModal'
import { trans, showTrans } from '../../../../../Utils'
function CountryItem(props) {
  const getTex = () => {
    if (child.registered_as) {
      return (child.registered_as)
    }
    else
      return (props.new ? showTrans(child.translations, "en") : showTrans(child.translations, props.lang))
  }
  const makeFollowerType = () => {
    if (child.registered_as) {
      props.makeFollower(props.level, child.registered_as, child.id);
    }
    else {
      props.makeFollower(props.level, child.translations.filter((t) => t.language_code === "en")[0].name, child.id);
    }
  }
  const checkActive = () => {
    if (child.registered_as) {
      return (props.languageFollower && props.languageFollower.filter((s) => s.name === child.registered_as).length > 0)
    }
    else {
      return (props.languageFollower && props.languageFollower.filter((s) => s.name === child.translations.filter((t) => t.language_code === "en")[0].name).length > 0)
    }
  }
  useEffect(() => {
    if (props.lang_id === child.id && props.open) {
      setExpand(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lang_id, props.open])
  // useEffect(()=>{
  //   if(props.languageFollower.filter((a)=>a.id===child.id).length===0){
  //     setExpand(false)
  //   }
  // },[props.languageFollower])
  const showImage = () => {
    if (child.parent_region_id === null) {
      if (child.flag_photo_path && child.flag_photo_path.length > 0) {

        return (<>
          <svg className='dept-child purea' data-src={process.env.REACT_APP_BASE_FILE_URL + child.flag_photo_path} width="23" height="15"></svg>
        </>)
      }
      else {

        if (child.translations.filter((a) => a.language_code === "en")[0].code && child.translations.filter((a) => a.language_code === "en")[0].code.length > 0) {
          return (
            <>
              <svg className='no-trans' xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                <g textAnchor='middle' id="Group_7626" data-name="Group 7626" transform="translate(-274 -138)">
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo" transform="translate(274 138)">
                    <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#ddd" />
                  </g>
                  <text textAnchor='middle' id="CFO" transform="translate(274 139)" fill="#404040" fontSize="9" fontFamily="SegoeUI, Segoe UI"><tspan textAnchor='middle' x="50%" y="10">{child.translations.filter((a) => a.language_code === "en")[0].code}</tspan></text>
                </g>
              </svg>
            </>)
        }
        else {
          return (<> <svg data-src={Empty} width="23" height="15"></svg></>)
        }
      }
    }
    else {
      if (child.flat_photo_path && child.flat_photo_path.length > 0) {
        return <img alt='' className='dept-child' src={process.env.REACT_APP_BASE_FILE_URL + child.flat_photo_path} width="23" height="15" />

      } else
        if (child.short_name && child.short_name.length > 0) {
          return (
            <>
              <svg className='no-trans' xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                <g textAnchor='middle' id="Group_7626" data-name="Group 7626" transform="translate(-274 -138)">
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo" transform="translate(274 138)">
                    <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#ddd" />
                  </g>
                  <text textAnchor='middle' id="CFO" transform="translate(274 139)" fill="#404040" fontSize="9" fontFamily="SegoeUI, Segoe UI"><tspan textAnchor='middle' x="50%" y="10">{child.short_name}</tspan></text>
                </g>
              </svg>
            </>)

        }
        else {
          return (<> <svg data-src={Empty} width="23" height="15"></svg></>)

        }
    }
  }
  const mode = useSelector((state) => state.companies.mode)
  useEffect(() => {
    if (!checkActive()) {
      setExpand(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.languageFollower])
  const { child, lang_id, setLang_id } = props
  const [expand, setExpand] = useState(lang_id === child.id)
  const data = useSelector(state => state.companies.newDepartment)
  useEffect(() => {
    if (mode === "add" && lang_id === child.id && props.open && child.translations.length > 0 && child.translations[0].short_name && child.translations[0].code.length > 0) {
      props.setModalData({ name: child.code + " Country", icon: child.icon, code: child.translations[0].code })
      setExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang_id, props.open])
  const request_running = useSelector((state) => state.companies.request_running)
  const request_data = useSelector((state) => state.companies.request_data)
  const error = useSelector((state) => state.companies.error)
  const message = useSelector((state) => state.companies.message)

  return (
    <>

      <div level={props.level} className={`${checkActive() ? "activated" : ""} dept parent-info ${expand ? "expand-line" : ""} ${(props.open) ? "disable-hover" : ""} ${props.accent}`} >
        {((child.communication_companies && child.communication_companies.length > 0) || ((child.services && child.services.length > 0)) || (child.subscriptions && child.subscriptions.length > 0)) &&
          <ExpandICon setExpand={() => { setLang_id(child.id); setExpand(!expand); makeFollowerType() }} expand={expand} />
        }
        <LoadingStatuse child={child} request_data={request_data} request_running={request_running} error={error} message={message} />
        {<div style={{ width: "23px" }} onClick={() => { setExpand(!expand); makeFollowerType(); setLang_id(child.id); }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-icon pure ${lang_id === child.id && "active-lang"}`}>
          {showImage()}
        </div>
        }

        <div className='parent-row'>
          <div onClick={() => { makeFollowerType(); setLang_id(child.id); if (child.parent_region_id !== null) props.Read(child) }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-name ${lang_id === child.id && "active-lang"}`}>{getTex()}</div>
          <div className={`icon-langs ${props.new}`}>
            {props.level < 4 &&
              <div className='icon-lang' onClick={() => { makeFollowerType(); setLang_id(child.id); props.Add(child); setExpand(true); }}>
                <div className='icon-lang-desc'>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                    <defs>
                      <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                      <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                    </g>
                  </svg>
                  <div className='icon-lang-desc-bu' style={{ width: "91px" }}>{trans({ id: "add", defaultMessage: "Add" })} {props.level > 1 ? props.level > 2 ? trans({ id: "Subscription", defaultMessage: "Subscription" }) : trans({ id: "service", defaultMessage: "Service" }) : trans({ id: "company", defaultMessage: "Company" })}</div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                  <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
                    <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                  </g>
                </svg>
              </div>}
            {((props.child.short_name) || (child.registered_as)) &&
              <div className='icon-lang' onClick={() => { makeFollowerType(); setLang_id(child.id); props.Update(child); }}>
                <div className='icon-lang-desc'>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                    <defs>
                      <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                      <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                    </g>
                  </svg>
                  <div className='icon-lang-desc-bu' style={{ left: "-25px" }}>{trans({ id: "edit", defaultMessage: "Edit" })}</div>
                </div>
                <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                  <g data-name="Mask Group 65" >
                    <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                      <g id="user" transform="translate(0.258)">
                        <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                        <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                        <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                        <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                        <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                      </g>
                      <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                    </g>
                  </g>
                </svg>
              </div>}
          </div>
        </div>
        {(expand && (child.communication_companies || child.services || child.subscriptions)) && (<CountriesChild lang={props.lang} setModalData={(e) => props.setModalData(e)} Active_Ids={props.Active_Ids} ActiveAdd={(e) => props.ActiveAdd(e)} setExpand={(e) => { setExpand(e) }} languageFollower={props.languageFollower} setLang_id={(e) => setLang_id(e)} {...props} level={props.level + 1} makeFollower={(e, w, id) => props.makeFollower(e, w, id)} child={child} />)}
      </div>
      {props.open && ((lang_id === child.id)) && ((props.level > 1) || (props.level === 1 && mode === "add")) && <CompainesModal level={props.level} Active_Ids={props.Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { setExpand(e) }} data={data} {...props} />}

    </>
  )
}

export default CountryItem