export default function Lebanon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
        <g id="Mask_Group_297" data-name="Mask Group 297">
            <g id="_018-lebanon" data-name="018-lebanon">
                <g id="Group_10623" data-name="Group 10623">
                    <path id="Path_21377" data-name="Path 21377" d="M23.128,4.31H1.872A1.872,1.872,0,0,0,0,6.183V7.759H25V6.183A1.872,1.872,0,0,0,23.128,4.31Z" fill="#ff4b55" />
                    <path id="Path_21378" data-name="Path 21378" d="M0,18.817A1.872,1.872,0,0,0,1.872,20.69H23.128A1.872,1.872,0,0,0,25,18.817V17.241H0Z" fill="#ff4b55" />
                </g>
                <rect id="Rectangle_4959" data-name="Rectangle 4959" width="25" height="9.483" transform="translate(0 7.759)" fill="#f5f5f5" />
                <path id="Path_21379" data-name="Path 21379" d="M13.421,15.4l3.983-.8a.4.4,0,0,0,.239-.63h0a.4.4,0,0,0-.256-.153l-4.244-.662a.217.217,0,0,1-.181-.25.227.227,0,0,1,.257-.177l2.942.387a.257.257,0,0,0,.221-.43l-.236-.252a.614.614,0,0,0-.331-.183l-2.458-.476,2.1.151a.179.179,0,0,0,.146-.3l-.107-.12a.457.457,0,0,0-.246-.142l-2.081-.435,1.479-.043a.154.154,0,0,0,.11-.256l-.066-.074a.414.414,0,0,0-.194-.121l-1.319-.379h1.036a.142.142,0,0,0,.131-.2l-.035-.081a.374.374,0,0,0-.223-.205l-.958-.326.471,0a.2.2,0,0,0,.178-.273h0a.49.49,0,0,0-.224-.24L12.5,8.19l-1.051.545a.49.49,0,0,0-.224.24h0a.2.2,0,0,0,.178.273l.471,0-.958.326a.373.373,0,0,0-.223.205l-.035.081a.142.142,0,0,0,.131.2h1.036l-1.319.379a.414.414,0,0,0-.194.121l-.066.074a.154.154,0,0,0,.11.256l1.479.043-2.081.435a.457.457,0,0,0-.246.142l-.107.12a.179.179,0,0,0,.146.3l2.1-.151-2.458.476a.614.614,0,0,0-.331.183l-.236.252a.257.257,0,0,0,.221.43l2.942-.387a.227.227,0,0,1,.257.177.217.217,0,0,1-.181.25l-4.244.662a.4.4,0,0,0-.256.153h0a.4.4,0,0,0,.239.63l3.983.8v.5a1.023,1.023,0,0,1-.3.724l-.621.621h3.684l-.621-.621a1.023,1.023,0,0,1-.3-.724v-.5h0Z" fill="#73af00" />
            </g>
        </g>
    </svg>
    )
}