import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { upload } from '../../../../../uploadService'
import LoadingImage from '../../../LoadingImage'
import InputText from '../../HRM/JobTitle/inputs/InputText'
import Empty from "../../icons/svgs/emptyIcon.svg"
import fill from "../../icons/svgs/fill.svg"
import flat from "../../icons/svgs/flat.svg"
import outline from "../../icons/svgs/outline.svg"
import X from "../../icons/svgs/xicon.svg"
import { trans } from '../../../../../Utils'
function CurrencyModal(props) {
  const dispatch = useDispatch()
  const img_upload = useSelector(state => state.currency.img_upload)
  const mode = useSelector(state => state.currency.mode)
  const modalData = useSelector(state => state.currency.modalData)
  const data = props.data
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal")
    setTimeout(() => {
      document.querySelector(".lang-modal.open").classList.remove("shake-modal")
    }, 400);
  }
  const { open, setOpen } = props
  const ChangeTrans = (lang_code, value) => {

    let translations = data.translations
    let arr = []
    translations.forEach((d) => {
      if (d.language_code === lang_code)
        arr.push({ ...d, name: value })
      else arr.push({ ...d })
    })
    dispatch({ type: "NEW-CUR", payload: { ...data, translations: [...arr] } })
  }
  const ChangeTransPiece = (lang_code, value) => {
    let translations = data.translations
    let arr = []
    translations.forEach((d) => {
      if (d.language_code === lang_code)
        arr.push({ ...d, one_percent_piece_name: value })
      else arr.push({ ...d })
    })
    dispatch({ type: "NEW-CUR", payload: { ...data, translations: [...arr] } })
  }
  const ChangeTransPiecCode = (lang_code, value) => {
    let translations = data.translations
    let arr = []
    translations.forEach((d) => {
      if (d.language_code === lang_code)
        arr.push({ ...d, one_percent_piece_code: value })
      else arr.push({ ...d })
    })
    dispatch({ type: "NEW-CUR", payload: { ...data, translations: [...arr] } })
  }
  const ChangeTransCode = (lang_code, value) => {
    let translations = data.translations
    let arr = []
    translations.forEach((d) => {
      if (d.language_code === lang_code)
        arr.push({ ...d, code: value })
      else arr.push({ ...d })
    })
    dispatch({ type: "NEW-CUR", payload: { ...data, translations: [...arr] } })
  }
  const ButtonValidate = () => {
    let emel = document.querySelectorAll("#cur_modal input")
    let s = 0
    emel.forEach((a) => {
      if (a.value.length === 0) {
        s = s + 1
      }
      else {

      }
    })
    if (s === 0) {
      return true

    }
    else {
      return false
    }

  }
  const delPhoto = (typ) => {
    let tmp = data
    tmp[`${typ}`] = null
    dispatch({ type: "NEW-CUR", payload: { ...tmp } })
  }
  const updateImage = async (file, type) => {
    if (mode !== "read") {
      dispatch({ type: "IMG-UPLOAD-CUR", payload: { status: true, type: type } })
      let pat = await upload(file)
      let tmp = { ...data }
      tmp[`${type}`] = pat

      dispatch({ type: "NEW-CUR", payload: { ...tmp } })
      dispatch({ type: "IMG-UPLOAD-CUR", payload: { status: false, type: null } })
      dispatch({ type: "LEVEL-DONE-CUR" })
    }
  }
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input")
      Image.onchange = (e) => {
        ;
        updateImage(e.target.files[0], name)
      }
      Image.type = "file"
      Image.hidden = true
      Image.accept = "image/svg+xml"
      Image.style = { position: "absolute", opacity: "0" }
      let i = document.body.appendChild(Image)
      i.click()
    }
  }
  const onDropImage = (file, type) => {
    if (mode !== "read") {
      file.preventDefault();

      if (file.dataTransfer.files.length === 1 && file.dataTransfer.files[0].type === "image/svg+xml") {
        updateImage(file.dataTransfer.files[0], type)
      }
      else {
        toast.error("Invalid File Type Svg Files are the Only Allowed File Type")
      }
    }
  }
  const onDragOver = (e) => {
    e.preventDefault()
  }
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {

        dispatch({ type: "ADD-CUR", payload: data })
      }
      else {
        dispatch({ type: "UPD-CUR", payload: data })

      }
    }
    else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal")
          inp.classList.add("red-bord")
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal")
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord")
          }, 1000);
        }
      })
    }
  }
  return (<>
    {mode !== "read" && <div onClick={(e) => shake(e)} className={`lang-modalDisable ${open && "open"}`}></div>}
    <div id="cur_modal" className={`lang-modal ${open && "open"} ${props.fixModal}`} style={{ minHeight: "416px", paddingBottom: "20px", height: "auto" }}>
      <div className='modal-top'>
        <div className='modal-top-title'>
          {mode === "add" ?
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none" />
                </clipPath>
              </defs>
              <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
                <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
              </g>
            </svg> :
            <>
              {data.flat_photo_path ?
                <svg className='emp-dept' data-src={process.env.REACT_APP_BASE_FILE_URL + data.flat_photo_path} width="15" height="15"></svg> :
                <svg className='emp-dept' data-src={Empty} width="23" height="15"></svg>}
            </>


          }
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
          {mode === "add" ? <div className='modal-title'>{trans({ id: "add", defaultMessage: "Add" })} {trans({ id: "new_currency", defaultMessage: "New Currency" })}</div> : <div className='modal-title' style={{ textTransform: "capitalize" }}>{mode === "update" ? trans({ id: "update", defaultMessage: "Update" }) : ""} {modalData.name} {trans({ id: "currency", defaultMessage: "Currency" })}</div>}
        </div>
        {mode !== "read" ? <div className='modal-top-buttons'>
          <div className={`add-button ${!ButtonValidate() && "disabled-button"}`} onClick={() => { sendRequest(); if (ButtonValidate()) { props.Close(); } }}>{mode === "add" ? trans({ id: "add", defaultMessage: "Add" }) : trans({ id: "update", defaultMessage: "Update" })}</div>
          <div className='close-button' onClick={() => { setOpen(false); props.Close() }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899">
              <g id="Group_4637" data-name="Group 4637" transform="translate(-224.153 -513.36) rotate(45)">
                <line id="Line_962" data-name="Line 962" x1="12" transform="translate(522.5 204.5)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                <line id="Line_963" data-name="Line 963" x1="12" transform="translate(528.5 198.5) rotate(90)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
              </g>
            </svg>
          </div>
        </div> :
          <div className='modal-top-buttons'>
            <div className='icon-lang marg' onClick={() => { props.Update(props.data) }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                <div className='icon-lang-desc-bu' style={{ whiteSpace: "nowrap", left: "-24px" }}>Edit Currency</div>
              </div>
              <svg className='gray-color' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g data-name="Mask Group 65" >
                  <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                    <g id="user" transform="translate(0.258)">
                      <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                      <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                      <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                      <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                      <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                    </g>
                    <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                  </g>
                </g>
              </svg>
            </div>
            <div className='close-button' onClick={() => { props.Close() }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899">
                <g id="Group_4637" data-name="Group 4637" transform="translate(-224.153 -513.36) rotate(45)">
                  <line id="Line_962" data-name="Line 962" x1="12" transform="translate(522.5 204.5)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                  <line id="Line_963" data-name="Line 963" x1="12" transform="translate(528.5 198.5) rotate(90)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                </g>
              </svg>
            </div>
          </div>
        }
      </div>
      <div className='modal-body'>
        <div className='modal-icon-section'>
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          {<div className='modal-icon-name'>{trans({ id: "currency_icon", defaultMessage: "Currency Icon" })}</div>}
          {
            <div className='modal-icons'>
              {!(img_upload.status && img_upload.type === "flat_photo_path") ?
                <div className='modal-icon' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "flat_photo_path") }} onDragOver={(e) => onDragOver(e)} onClick={() => updatPhoto("flat_photo_path")}>
                  {data.flat_photo_path && <span onClick={() => delPhoto("flat_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.flat_photo_path && data.flat_photo_path.length > 0 ? <svg data-src={process.env.REACT_APP_BASE_FILE_URL + data.flat_photo_path} width="50" height="50"></svg> : <img alt="language-flat" style={{ marginLeft: "5px" }} src={flat} />}</div> : <div className='modal-icon'><LoadingImage />
                </div>}
              {!(img_upload.status && img_upload.type === "outline_photo_path") ?
                <div className='modal-icon mar' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "outline_photo_path") }} onDragOver={(e) => onDragOver(e)} onClick={() => updatPhoto("outline_photo_path")}>
                  {data.outline_photo_path && <span onClick={() => delPhoto("outline_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.outline_photo_path && data.outline_photo_path.length > 0 ? <svg data-src={process.env.REACT_APP_BASE_FILE_URL + data.outline_photo_path} width="50" height="50"></svg> : <img alt="language-flat" style={{ marginLeft: "5px" }} src={outline} />}</div> : <div className='modal-icon mar'><LoadingImage /></div>}
              {!(img_upload.status && img_upload.type === "fill_photo_path") ? <div className='modal-icon mar' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "fill_photo_path") }} onDragOver={(e) => onDragOver(e)} onClick={() => updatPhoto("fill_photo_path")}>
                {data.fill_photo_path && <span onClick={() => delPhoto("fill_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                {data.fill_photo_path && data.fill_photo_path.length > 0 ? <svg data-src={process.env.REACT_APP_BASE_FILE_URL + data.fill_photo_path} width="50" height="50"></svg> : <img alt="language-flat" src={fill} />}</div> : <div className='modal-icon mar'><LoadingImage /></div>}

            </div>

          }
        </div>
        <div className='modal-input-section'>
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          <div className='modal-icon-name'>{trans({ id: "currency_code", defaultMessage: "Currency Code" })}</div>
          <div className='input-section'>
            {data.translations.map((a, i) => (
              <div className='input-holder' style={{ marginTop: i !== 0 ? "9px" : "0px" }}>
                <div className='short-input-element' style={{ width: "108px" }}>
                  <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                    <text id="AR" transform="translate(0 11)" fill="#CECDCD" fontSize="10" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="0" y="0">{a.language_code.toUpperCase()}</tspan></text>
                  </svg>
                  <input style={{ width: "108px" }} readOnly={mode === "read"} className='pad20' onChange={(e) => {
                    if (e.target.value.length <= 15) {
                      ChangeTransCode(a.language_code, e.target.value)
                    }
                  }} value={data.translations.filter((sd) => sd.language_code === a.language_code)[0].code} type="text" /><img onClick={() => ChangeTransCode(a.language_code, "")} alt="close" src={X} />
                </div>
                <div className='full-input-element pa' style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                  <InputText style={{ width: "-webkit-fill-available" }} disabled={mode === "read"} code={data.translations.filter((t) => t.language_code === a.language_code)[0].language_code} pad={true} clear={() => { ChangeTrans(data.translations.filter((t) => t.language_code === a.language_code)[0].language_code, "") }} lang={data.translations.filter((t) => t.language_code === a.language_code)[0].name} setLang={(e) => { ChangeTrans(data.translations.filter((t) => t.language_code === a.language_code)[0].language_code, e.target.value) }} /></div>
              </div>
            ))}

          </div>
          <div className='modal-icon-name' style={{ marginTop: "12px" }}>1/100 {trans({ id: "currency_name", defaultMessage: "Currency Name" })}</div>
          <div className='input-section'>
            {data.translations.map((a, i) => (
              <div className='input-holder' style={{ marginTop: i !== 0 ? "9px" : "0px" }}>
                <div className='short-input-element' style={{ width: "108px" }}>
                  <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                    <text id="AR" transform="translate(0 11)" fill="#CECDCD" fontSize="10" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="0" y="0">{a.language_code.toUpperCase()}</tspan></text>
                  </svg>
                  <input style={{ width: "108px" }} readOnly={mode === "read"} className='pad20' onChange={(e) => {
                    if (e.target.value.length <= 15) {
                      ChangeTransPiecCode(a.language_code, e.target.value)
                    }
                  }} value={data.translations.filter((sd) => sd.language_code === a.language_code)[0].one_percent_piece_code} type="text" /><img onClick={() => ChangeTransPiecCode(a.language_code, "")} alt="close" src={X} />
                </div>
                <div className='full-input-element pa' style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                  <InputText style={{ width: "-webkit-fill-available" }} disabled={mode === "read"} code={data.translations.filter((t) => t.language_code === a.language_code)[0].language_code} pad={true} clear={() => { ChangeTransPiece(data.translations.filter((t) => t.language_code === a.language_code)[0].language_code, "") }} lang={data.translations.filter((t) => t.language_code === a.language_code)[0].one_percent_piece_name ? data.translations.filter((t) => t.language_code === a.language_code)[0].one_percent_piece_name : ""} setLang={(e) => { ChangeTransPiece(data.translations.filter((t) => t.language_code === a.language_code)[0].language_code, e.target.value) }} /></div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>

  </>
  )
}

export default CurrencyModal