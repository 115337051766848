import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Application from '../../../../assets/svg/Application';
import Filter from '../../../../assets/svg/Filter';
import Search from '../../../../assets/svg/search';
import Sort from '../../../../assets/svg/Sort';
import Translated from '../../../../assets/svg/Translated';
import Curre from "../../../../assets/svg/Langs.svg";
import './translate.css'
import { Table } from 'ka-table';
import { hideNewRow, saveNewRow, showNewRow } from 'ka-table/actionCreators';
import { DataType } from 'ka-table/enums';
import "ka-table/style.scss";
import { trans } from '../../../../Utils'

const getHead = (languages) => {
  let temp_head = [];
  let index = 3;
  temp_head[0] = {
    key: 'addColumn', style: { width: 53 },
  }
  temp_head[1] = { key: 'id', title: 'ID', dataType: DataType.Number, visible: false }
  temp_head[2] = { key: 'key', title: 'KEY', dataType: DataType.String }
  languages.forEach((lang) => {
    temp_head[index] = { key: lang.language_code.toUpperCase(), title: lang.language_code.toUpperCase(), dataType: DataType.String }
    index++
  })

  return temp_head
}
const AddButton = ({ dispatch }) => {
  return (
    <div className="plus-cell-button">
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/plus.svg"
        alt="Add New Row"
        title="Add New Row"
        onClick={() => dispatch(showNewRow())}
      />
    </div>
  );
};

const SaveButton = (props) => {
  const saveNewData = () => {
    props.dispatch(
      saveNewRow(props.maxId, {
        validate: false
      })
    );
  };
  return (
    <div className="buttons">
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/save.svg"
        className="save-cell-button"
        alt="Save"
        title="Save"
        onClick={() => {
          saveNewData();
        }}
      />
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/close.svg"
        className="close-cell-button"
        alt="Cancel"
        title="Cancel"
        onClick={() => props.dispatch(hideNewRow())}
      />
    </div>
  );
};
const changeColumns = (dispatch, cols) => {
  dispatch({ type: "colomns_change", payload: cols })
}
const changeData = (dispatch, data) => {
  dispatch({ type: "data_change", payload: data })
}
const getFile = ({ translations, lang }) => {
  return (translations[`${lang}`])
}
function TranslateComponent(props) {
  const tablePropsInit = useSelector((state) => state.tablePropsInit);
  const languages = useSelector(state => state.langs.languages)
  const translations = useSelector(state => state.translationsReducer.langs)
  const dispatch = useDispatch()
  const translated_lang = useSelector((state) => state.auth.lang)
  const [files, setFiles] = useState([]);
  const [head, setHead] = useState(null);
  const [rows, setRows] = useState(null);
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    let temp = [];
    let temp_keys = [];
    let temp_head = languages.length > 0 && getHead(languages);

    let temp_data = []
    languages.forEach((lang, index) => {
      if (lang.file_path) {
        let file = getFile({ translations, lang: lang.language_code.toLowerCase() })
        if (index === 0) {
          temp[index] = file;
          temp_keys.push(...Object.keys(file))
          Object.keys(file).forEach((key, index) => {
            temp_data.push({ id: index, key: key })
          })
          Object.values(file).forEach((value, index1) => temp_data[index1] = { ...temp_data[index1], [lang.language_code.toUpperCase()]: value })
        }
        else {
          temp[index] = file;
          Object.keys(file).forEach((key) => {
            if (!temp_keys.find(temp_key => temp_key === key)) {
              temp_data.push({ id: temp_keys.length, key: key })
              temp_keys.push(key)
            }
          })
          Object.values(file).forEach((value, index1) => {
            let var_index = temp_data.findIndex(element => element.key === Object.keys(file)[index1])
            temp_data[var_index] = { ...temp_data[var_index], [lang.language_code.toUpperCase()]: value }
          })
        }
      }
      else {
        temp[index] = null;
      }
    })
    setRows(temp_data)
    setFiles(temp)
    setHead(temp_head)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    setIsReady(false)
    if (rows && head && head.length > 0) {
      changeColumns(dispatch, head)
      changeData(dispatch, rows)
      setIsReady(true)
    } else {
      setIsReady(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, head, files])
  const [searchText, setSearchText] = useState('');
  return (
    <>
      <div className={`languages-page ${props.active && "Active-page"}`}>

        <div className='lang-top'>
          <div className='lang-info'>
            <div className='lang-icon header-icon'>
              <img alt="Languages" src={Curre} />
            </div>
            <div className='lang-name'>{trans({ id: "translate", defaultMessage: "Translate" })}</div>

          </div>
          <div className='top-options'>
            <div className='top-option'>
              <span><Search /></span>
            </div>
            <div className='top-option langs'>
              <span><Translated /></span>
              <div className='lang-items'>
                {languages.map((langs, index) => <div key={index} onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })} className={`lang-item  ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
              </div>
            </div>
            <div className='top-option'>
              <span><Sort /></span>
            </div>
            <div className='top-option'>
              <span><Filter /></span>
            </div>
            <div className='top-option'>
              <span><Application /></span>
            </div>
            <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
              <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
            </svg>
          </div>
          <div className={`lang-add currency-add`}>
            <button className='validate-button' onClick={() => {
              dispatch({ type: "UploadLanguagesFiles", payload: { langs: languages, data: tablePropsInit.data } })
            }}>{trans({ id: "save", defaultMessage: "Save" })}</button>
          </div>
        </div>
        <div className='trans'>
          {isReady && <>
            <input type='search' placeholder="Search Here" value={searchText} onChange={(event) => {
              setSearchText(event.currentTarget.value);
            }} className='top-element' />

            <Table {...tablePropsInit}
              dispatch={dispatch}
              searchText={searchText}
              childComponents={{
                noDataRow: {
                  content: () => 'No Data Found'
                },
                cellEditor: {
                  content: (props) => {
                    if (props.column.key === 'addColumn' && props.editingMode === "none") {
                      return <SaveButton {...props} maxId={tablePropsInit.data.length} />;
                    } else if (props.column.key === 'addColumn' && props.editingMode !== "none") {
                      return (<></>)
                    }
                  },
                },
                headCell: {
                  content: (props) => {
                    if (props.column.key === 'addColumn') {
                      return <AddButton {...props} />;
                    }
                  },
                },
              }}
            />
          </>}
        </div>
      </div>
    </>
  )
}

export default TranslateComponent