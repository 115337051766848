import React from 'react'
import { useSelector } from 'react-redux'
import { trans } from '../../../../Utils'
function StatusBar(props) {
    const employee = useSelector(state => state.employee.employee)
    const setStatus = (da) => {
        if (props.workFilter?.includes(da)) {
            props.setWorkFilter(props.workFilter.filter((a) => a !== da))
        }
        else {
            props.setWorkFilter([...props.workFilter, da])
        }
    }
    const getStatus = (da) => {
        return props.workFilter?.includes(da)
    }
    return (
        <div className="status-bar">
            <div className={`status-element ${getStatus("in") && 'status-element-active'}`} onClick={() => setStatus("in")}>
                <div className='inner-status'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <g id="Ellipse_396" data-name="Ellipse 396" fill="#98fd7f" stroke="#707070" strokeWidth="0.5">
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.75" fill="none" />
                        </g>
                    </svg>

                    <div className='inner-text'>{trans({ id: "in_work", defaultMessage: "In Work" })} {employee.filter((a) => a.status === "in").length}</div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5" height="14.5" viewBox="0 0 0.5 14.5">
                <line id="Line_1095" data-name="Line 1095" y2="14" transform="translate(0.25 0.25)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="0.5" />
            </svg>

            <div className={`status-element ${getStatus("br") && 'status-element-active'}`} onClick={() => setStatus("br")}>
                <div className='inner-status'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <g id="Ellipse_400" data-name="Ellipse 400" fill="#ffc874" stroke="#707070" strokeWidth="0.5">
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.75" fill="none" />
                        </g>
                    </svg>



                    <div className='inner-text'>{trans({ id: "break", defaultMessage: "Break" })} {employee.filter((a) => a.status === "br").length}</div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5" height="14.5" viewBox="0 0 0.5 14.5">
                <line id="Line_1095" data-name="Line 1095" y2="14" transform="translate(0.25 0.25)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="0.5" />
            </svg>

            <div className={`status-element ${getStatus("out") && 'status-element-active'}`} onClick={() => setStatus("out")}>
                <div className='inner-status'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <g id="Ellipse_396" data-name="Ellipse 396" fill="#FD8585" stroke="#707070" strokeWidth="0.5">
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.75" fill="none" />
                        </g>
                    </svg>

                    <div className='inner-text'>{trans({ id: "out_work", defaultMessage: "Out Work" })} {employee.filter((a) => a.status === "out").length}</div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5" height="14.5" viewBox="0 0 0.5 14.5">
                <line id="Line_1095" data-name="Line 1095" y2="14" transform="translate(0.25 0.25)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="0.5" />
            </svg>

            <div className={`status-element ${getStatus("fi") && 'status-element-active'}`} onClick={() => setStatus("fi")}>
                <div className='inner-status'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <g id="Ellipse_399" data-name="Ellipse 399" fill="#cecdcd" stroke="#707070" strokeWidth="0.5">
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.75" fill="none" />
                        </g>
                    </svg>
                    <div className='inner-text'>{trans({ id: "finish_work", defaultMessage: "Finish Work" })} {employee.filter((a) => a.status === "fi").length}</div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5" height="14.5" viewBox="0 0 0.5 14.5">
                <line id="Line_1095" data-name="Line 1095" y2="14" transform="translate(0.25 0.25)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="0.5" />
            </svg>

            <div className={`status-element ${getStatus("re") && 'status-element-active'}`} onClick={() => setStatus("re")} style={{ justifyContent: "flex-end" }}>
                <div className='inner-status'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <path id="Path_15157" data-name="Path 15157" d="M9.878,36.534,6.13,32.249c-.262-.3-.711-.088-.711.337v1.907A5.824,5.824,0,0,0,.263,39.105,8.481,8.481,0,0,0,0,41.495c0,.092,0,.118,0,.137a.411.411,0,0,0,.774.244,7.133,7.133,0,0,1,1.568-2.089,3.915,3.915,0,0,1,3.071-1.079v2.448c0,.424.449.637.711.337l3.748-4.285A.526.526,0,0,0,9.878,36.534Z" transform="translate(0 -32.109)" fill="#ff5858" />
                    </svg>
                    <div className='inner-text'>{trans({ id: "reject", defaultMessage: "Reject" })} {employee.filter((a) => a.status === "re").length}</div>
                </div>
            </div>
        </div>
    )
}

export default StatusBar