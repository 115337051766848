import Search from '../../../../assets/svg/search'
import Translated from '../../../../assets/svg/Translated'
import Sort from '../../../../assets/svg/Sort'
import Filter from '../../../../assets/svg/Filter'
import Application from '../../../../assets/svg/Application'
import FollowerIcon from '../../../../assets/svg/FollowerIcon'
import { useSelector, useDispatch } from "react-redux"

export const TopNav = ({ table_icon, top_name_clk, table_name, follower, followerclk, setLang, disableAdd, add_button_clk, haveView, viewData }) => {

    const languages = useSelector(state => state.langs.languages)
    const translated_lang = useSelector(state => state.auth.lang)
    const dispatch = useDispatch()

    return (<div className='lang-top'>
        <div className='lang-info'>
            <div className='lang-icon header-icon'>
                <img alt="Languages" src={table_icon} />
            </div>
            <div className='lang-name'
                onClick={() => {
                    top_name_clk()
                }}>
                {table_name}
            </div>
            <div className='follower-list'>
                {follower?.map((fo, index) => (
                    <div key={index} className='follower'
                        onClick={() => {
                            followerclk(index, fo.name, fo.id)
                        }}>
                        <span><FollowerIcon expand={false} /></span>
                        <span>{fo.name}</span>
                    </div>

                ))}
            </div>
        </div>
        <div className='top-options'>
            <div className='top-option'>
                <span><Search /></span>
            </div>
            <div className='top-option langs'>
                <span><Translated /></span>
                <div className='lang-items'>
                    {languages.map((langs, index) => <div key={index}
                        onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })}
                        onMouseLeave={() => setLang(null)}
                        onMouseEnter={() => setLang(langs.language_code)}
                        className={`lang-item  ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>
                        {langs.language_code.toUpperCase()}
                    </div>)}
                </div>
            </div>
            <div className='top-option'>
                <span><Sort /></span>
            </div>
            <div className='top-option'>
                <span><Filter /></span>
            </div>
            <div className='top-option'>
                <span><Application /></span>
            </div>
            <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
                <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
            </svg>
        </div>
        <div className={`lang-add currency-add`}>
            {haveView ? viewData() : <></>}
            {disableAdd ? <><svg className={'addlines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
                <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" stroke-linecap="round" stroke-width="0.5" />
            </svg>
                <svg className={'disab'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
                        <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                    </g>
                </svg></>
                : <svg className={disableAdd && 'disab'} onClick={() => { add_button_clk() }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
                        <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
                    </g>
                </svg>}

        </div>
    </div>)
}
