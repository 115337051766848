import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import lock from "../../assets/svg/lock.svg";
import "./index.css";
import Footer from "./Footer";
import Avatar from "./Avatar";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { trans } from '../../Utils'
function Signup(props) {
    const dispatch = useDispatch();
    const setPass = (a) => { dispatch({ type: "PASS", payload: a }); }
    const [pin, setPin] = useState("");
    const [name, setName] = useState("");
    const [locks, setLock] = useState(true);
    const [attempt, setAttempt] = useState(3);
    const [counter, setCounter] = useState(0);
    const [initial, setInitial] = useState(true);
    useEffect(() => {
        if (attempt === 0) {
            setCounter(10);
        }
        if (attempt !== 3) {
            setTimeout(() => {
                setInitial(false);
                setPin("");
                setInitial(true);
                document.querySelector(".pincode-input-text").focus();
            }, 400);
        }
    }, [attempt]);
    useEffect(() => {
        if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
        else {
            document.querySelector(".pincode-input-text").focus();
            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.remove("red-background");
            });
            setAttempt(3);
        }
    }, [counter]);
    useEffect(() => {
        let arr = document.querySelectorAll(".absolute-child");
        arr.forEach((elem) => {
            elem.addEventListener(
                "select",
                function (e) {
                    e.selecionStart = e.selecionEnd;
                },
                false
            );
        });
    }, []);
    const [signedIn, setSignedIn] = useState(false);
    useEffect(() => {
        if (
            localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).id
        ) {
            setSignedIn(true);
        } else {
            setSignedIn(false);
        }
    }, []);
    const shake = (e) => {
        if (localStorage.getItem("user")) {
            dispatch({
                type: "LOGIN_SAGA",
                payload: {
                    username:
                        localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user")).username,
                    password: e,
                },
            });
        }
        else {
            dispatch({
                type: "LOGIN_SAGA",
                payload: { username: name, password: e },
            });
            ;
        }
    };
    const user = useSelector((state) => state.auth.user);
    const error = useSelector((state) => state.auth.error);
    const loading = useSelector((state) => state.general.loading);

    useEffect(() => {
        if (user) {
        }

        if (user && user?.id && !loading) {
            setLock(false);
            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.remove("red-background");
                elem.classList.add("green-background");
            });
            setPass(true);
            setTimeout(() => {
                localStorage.setItem("user", JSON.stringify(user));
                props.history.push("/");
            }, 1000);
        }
        if (error && !loading) {
            setLock(true);
            setAttempt(attempt - 1);
            document.querySelector(".pin-input").classList.add("shake-modals");
            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.add("red-background");
                elem.classList.remove("green-background");
            });
            setTimeout(() => {
                document.querySelector(".pin-input").classList.remove("shake-modals");
                document.querySelectorAll(".absolute-child").forEach((elem) => {
                    elem.classList.remove("red-background");
                });
            }, 400);
        }
        // eslint-disable-next-line
    }, [user, loading]);
    const onChange = () => {
        setLock(true);
        document.querySelectorAll(".absolute-child").forEach((elem) => {
            elem.classList.remove("red-background");
            elem.classList.remove("green-background");
        });
    };
    const [options, setOptions] = useState(false);
    return (
        <div className="main full-page sign" data-testid="sign-up-page">
            <Footer />
            <div className="signup-main">
                {signedIn && <Avatar />}
                {/* <Logo/> */}
                <div className="signup-container">
                    {!localStorage.getItem("user") && (
                        <div className="relative-input">
                            {!locks ? (
                                <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                    >
                                        <g>
                                            <g transform="translate(3.652 0)">
                                                <g id="_259295200">
                                                    <path
                                                        id="_259294984"
                                                        d="M14.535,7.7H5.465A1.819,1.819,0,0,0,3.652,9.514v8.672A1.819,1.819,0,0,0,5.465,20h9.069a1.819,1.819,0,0,0,1.814-1.814V9.514A1.819,1.819,0,0,0,14.535,7.7ZM10,11.412a1.62,1.62,0,0,0-1.039,2.863v2.012h2.078V14.276A1.62,1.62,0,0,0,10,11.412Z"
                                                        transform="translate(-3.652 0)"
                                                        fill="#5d5d5d"
                                                        fillRule="evenodd"
                                                    />
                                                    <path
                                                        id="_259295344"
                                                        d="M14.622,8.046V4.591A4.332,4.332,0,0,0,11.866.357a5.052,5.052,0,0,0-3.732,0A4.332,4.332,0,0,0,5.378,4.591h1.3A3.089,3.089,0,0,1,8.615,1.557a3.806,3.806,0,0,1,2.77,0,3.089,3.089,0,0,1,1.941,3.034V8.046Z"
                                                        transform="translate(-3.652 0)"
                                                        fill="#5d5d5d"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                    >
                                        <g>
                                            <g>
                                                <g id="_259295200">
                                                    <path
                                                        id="_259294984"
                                                        d="M5.465,7.7h9.069a1.819,1.819,0,0,1,1.814,1.814v8.672A1.819,1.819,0,0,1,14.535,20H5.465a1.819,1.819,0,0,1-1.814-1.814V9.514A1.819,1.819,0,0,1,5.465,7.7ZM10,11.412a1.62,1.62,0,0,1,1.039,2.863v2.012H8.961V14.276A1.62,1.62,0,0,1,10,11.412Z"
                                                        fill="#8e8e8e"
                                                        fillRule="evenodd"
                                                    />
                                                    <path
                                                        id="_259295344"
                                                        d="M5.378,8.046V4.591A4.332,4.332,0,0,1,8.134.357a5.052,5.052,0,0,1,3.732,0,4.332,4.332,0,0,1,2.756,4.234V8.046h-1.3V4.591a3.089,3.089,0,0,0-1.941-3.034,3.806,3.806,0,0,0-2.77,0A3.089,3.089,0,0,0,6.674,4.591V8.046Z"
                                                        fill="#8e8e8e"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </>
                            )}
                            <input
                                onKeyDown={(e) => {
                                    if (e.code === "Enter")
                                        document.querySelector(".pincode-input-text").focus();
                                }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="username"
                                placeholder="User Name"
                            />
                        </div>
                    )}
                    <div className="pin-input">
                        <div className="absolute-container">
                            <div className="absolute-child">
                                {pin.length > 0 && <img alt="lock" src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 1 && <img alt="lock" src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 2 && <img alt="lock" src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 3 && <img alt="lock" src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 4 && <img alt="lock" src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 5 && <img alt="lock" src={lock} />}
                            </div>
                        </div>
                        {initial && (
                            <PinInput
                                length={6}
                                initialValue=""
                                onChange={(value, index) => {
                                    setPin(value);
                                    onChange();
                                }}
                                type="numeric"
                                disabled={counter > 0}
                                inputMode="number"
                                style={{ marginTop: 0 }}
                                onComplete={(value, index) => shake(value)}
                                inputStyle={{
                                    borderRadius: 15,
                                    backgroundColor: "#F7F7F7",
                                    margin: "initial",
                                    color: "transparent",
                                    border: "#ddddddc5 0.5px solid",
                                    width: 50,
                                    height: 50,
                                }}
                                // onComplete={(value, index) => setPin(value)}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}

                            // disabled={disablePin}
                            />
                        )}
                    </div>
                    <div className="text-container">
                        {attempt < 3 && (
                            <>
                                {attempt !== 0 ? (
                                    <div className="attempt">{trans({ id: "you_have", defaultMessage: "You Have" })} {attempt} {trans({ id: "attempts", defaultMessage: "Attempts" })} </div>
                                ) : (
                                    <div className="counter">
                                        {trans({ id: "try_after", defaultMessage: "Try After" })} {counter}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {signedIn && (
                    <div className="signup-options">
                        <div
                            className={`mobile-options  ${options && "active-button"}`}
                            onClick={() => setOptions(!options)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                            >
                                <g
                                    id="Group_7964"
                                    data-name="Group 7964"
                                    transform="translate(-190 -520)"
                                >
                                    <g
                                        id="Rectangle_4719"
                                        data-name="Rectangle 4719"
                                        transform="translate(190 520)"
                                        fill="#f7f7f7"
                                        stroke="#ddd"
                                        strokeWidth="0.5"
                                    >
                                        <rect width="50" height="50" rx="15" stroke="none" />
                                        <rect
                                            x="0.25"
                                            y="0.25"
                                            width="49.5"
                                            height="49.5"
                                            rx="14.75"
                                            fill="none"
                                        />
                                    </g>
                                    <g
                                        id="_15x15_photo_back"
                                        data-name="15x15 photo back"
                                        transform="translate(208 538)"
                                    >
                                        <g id="Mask_Group_215" data-name="Mask Group 215">
                                            <g
                                                id="Layer_2"
                                                data-name="Layer 2"
                                                transform="translate(-0.682 -0.682)"
                                            >
                                                <path
                                                    id="Path_19238"
                                                    data-name="Path 19238"
                                                    d="M3.409,6.136A2.727,2.727,0,1,1,6.136,3.409,2.727,2.727,0,0,1,3.409,6.136Zm0-4.091A1.364,1.364,0,1,0,4.773,3.409,1.364,1.364,0,0,0,3.409,2.045Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19239"
                                                    data-name="Path 19239"
                                                    d="M12.955,10.909a2.727,2.727,0,1,1,2.727-2.727A2.727,2.727,0,0,1,12.955,10.909Zm0-4.091a1.364,1.364,0,1,0,1.364,1.364A1.364,1.364,0,0,0,12.955,6.818Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19240"
                                                    data-name="Path 19240"
                                                    d="M3.409,15.682a2.727,2.727,0,1,1,2.727-2.727A2.727,2.727,0,0,1,3.409,15.682Zm0-4.091a1.364,1.364,0,1,0,1.364,1.364A1.364,1.364,0,0,0,3.409,11.591Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19241"
                                                    data-name="Path 19241"
                                                    d="M14.318,4.091H5.455V2.727h8.864a.682.682,0,0,1,0,1.364Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19242"
                                                    data-name="Path 19242"
                                                    d="M10.909,8.864H2.045a.682.682,0,1,1,0-1.364h8.864Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19243"
                                                    data-name="Path 19243"
                                                    d="M14.318,13.636H5.455V12.273h8.864a.682.682,0,0,1,0,1.364Z"
                                                    fill="#8e8e8e"
                                                />
                                                <path
                                                    id="Path_19244"
                                                    data-name="Path 19244"
                                                    d="M0,2.045Z"
                                                    transform="translate(10.909 6.136)"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div
                            className="signup-option del-option"
                            onClick={() => {
                                setPass(false)
                                dispatch({ type: "log_out" })
                                localStorage.removeItem("user");
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500)
                            }}
                        >
                            <svg
                                className="bord"
                                xmlns="http://www.w3.org/2000/svg"
                                width="350"
                                height="50"
                                viewBox="0 0 350 50"
                            >
                                <g
                                    id="Rectangle_4720"
                                    data-name="Rectangle 4720"
                                    fill="#f7f7f7"
                                    stroke="#ddd"
                                    strokeWidth="0.5"
                                >
                                    <rect width="350" height="50" rx="15" stroke="none" />
                                    <rect
                                        x="0.25"
                                        y="0.25"
                                        width="349.5"
                                        height="49.5"
                                        rx="14.75"
                                        fill="none"
                                    />
                                </g>
                            </svg>
                            <svg
                                id="_15x15_photo_back"
                                data-name="15x15 photo back"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                            >
                                <g id="Mask_Group_211" data-name="Mask Group 211">
                                    <g id="bin">
                                        <g id="Group_7944" data-name="Group 7944">
                                            <g id="Group_7943" data-name="Group 7943">
                                                <path
                                                    id="Path_19227"
                                                    data-name="Path 19227"
                                                    d="M1.822,4.395l.778,9.4A1.325,1.325,0,0,0,3.914,15h7.172A1.325,1.325,0,0,0,12.4,13.791l.778-9.4ZM5.3,13.242a.439.439,0,0,1-.438-.412L4.425,5.74a.439.439,0,0,1,.411-.466.433.433,0,0,1,.466.411l.439,7.09A.44.44,0,0,1,5.3,13.242ZM7.939,12.8a.439.439,0,0,1-.879,0V5.713a.439.439,0,0,1,.879,0ZM10.575,5.74l-.439,7.09a.439.439,0,0,1-.877-.055L9.7,5.685a.447.447,0,0,1,.466-.411A.439.439,0,0,1,10.575,5.74Z"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                        <g id="Group_7946" data-name="Group 7946">
                                            <g id="Group_7945" data-name="Group 7945">
                                                <path
                                                    id="Path_19228"
                                                    data-name="Path 19228"
                                                    d="M13.213,1.758H10.576V1.318A1.32,1.32,0,0,0,9.258,0H5.742A1.32,1.32,0,0,0,4.424,1.318v.439H1.787a.879.879,0,0,0,0,1.758H13.213a.879.879,0,0,0,0-1.758Zm-3.516,0H5.3V1.318A.44.44,0,0,1,5.742.879H9.258a.44.44,0,0,1,.439.439Z"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div className="option-text">
                                {trans({ id: "clear_login", defaultMessage: "Clear Login" })}
                            </div>
                        </div>
                        <div className="signup-option change-option">
                            <svg
                                className="bord"
                                xmlns="http://www.w3.org/2000/svg"
                                width="350"
                                height="50"
                                viewBox="0 0 350 50"
                            >
                                <g
                                    id="Rectangle_4720"
                                    data-name="Rectangle 4720"
                                    fill="#f7f7f7"
                                    stroke="#ddd"
                                    strokeWidth="0.5"
                                >
                                    <rect width="350" height="50" rx="15" stroke="none" />
                                    <rect
                                        x="0.25"
                                        y="0.25"
                                        width="349.5"
                                        height="49.5"
                                        rx="14.75"
                                        fill="none"
                                    />
                                </g>
                            </svg>
                            <svg
                                id="_15x15_photo_back"
                                data-name="15x15 photo back"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                            >
                                <g id="Mask_Group_212" data-name="Mask Group 212">
                                    <g id="exchange">
                                        <g id="Group_7948" data-name="Group 7948">
                                            <g id="Group_7947" data-name="Group 7947">
                                                <path
                                                    id="Path_19229"
                                                    data-name="Path 19229"
                                                    d="M9.167,13.9A6.615,6.615,0,0,1,1.313,9.836a6.542,6.542,0,0,1-.1-4.385A6.66,6.66,0,0,1,3,2.654L2.849,3.9l.877.106.332-2.754L1.339.934l-.1.877,1.219.144A7.548,7.548,0,0,0,.373,5.176,7.5,7.5,0,0,0,9.39,14.757Z"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                        <g id="Group_7950" data-name="Group 7950">
                                            <g id="Group_7949" data-name="Group 7949">
                                                <path
                                                    id="Path_19230"
                                                    data-name="Path 19230"
                                                    d="M14.545,10.062A7.5,7.5,0,0,0,5.184.37l.274.84a6.611,6.611,0,0,1,6.107,11.5l.164-1.359-.877-.106L10.519,14l2.719.322.1-.877-1.115-.132A7.517,7.517,0,0,0,14.545,10.062Z"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                        <g
                                            id="Group_7954"
                                            data-name="Group 7954"
                                            transform="translate(1.149 -0.508)"
                                        >
                                            <g id="Group_7953" data-name="Group 7953">
                                                <path
                                                    id="Path_19232"
                                                    data-name="Path 19232"
                                                    d="M8.12,7.744a1.873,1.873,0,1,0-2.91,0A2.649,2.649,0,0,0,3.547,10.2v.442H9.788V10.2A2.649,2.649,0,0,0,8.12,7.744Z"
                                                    fill="#8e8e8e"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <div className="option-text">{trans({ id: "change_user", defaultMessage: "Change User" })}</div>
                        </div>
                        <div className="signup-option forget-option">
                            <svg
                                className="bord"
                                xmlns="http://www.w3.org/2000/svg"
                                width="350"
                                height="50"
                                viewBox="0 0 350 50"
                            >
                                <g
                                    id="Rectangle_4720"
                                    data-name="Rectangle 4720"
                                    fill="#f7f7f7"
                                    stroke="#ddd"
                                    strokeWidth="0.5"
                                >
                                    <rect width="350" height="50" rx="15" stroke="none" />
                                    <rect
                                        x="0.25"
                                        y="0.25"
                                        width="349.5"
                                        height="49.5"
                                        rx="14.75"
                                        fill="none"
                                    />
                                </g>
                            </svg>
                            <svg
                                id="_15x15_photo_back"
                                data-name="15x15 photo back"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                            >
                                <g id="Mask_Group_213" data-name="Mask Group 213">
                                    <g id="forgot-password">
                                        <path
                                            id="Path_19233"
                                            data-name="Path 19233"
                                            d="M5.01,4.248a2.49,2.49,0,0,1,4.98,0v1.23h1.758V4.248a4.248,4.248,0,0,0-8.5,0v1.23H5.01Z"
                                            fill="#8e8e8e"
                                        />
                                        <path
                                            id="Path_19234"
                                            data-name="Path 19234"
                                            d="M12.48,6.357H2.52A.439.439,0,0,0,2.08,6.8v7.764A.439.439,0,0,0,2.52,15H12.48a.439.439,0,0,0,.439-.439V6.8A.439.439,0,0,0,12.48,6.357ZM7.5,13.2a.439.439,0,1,1,.439-.439A.439.439,0,0,1,7.5,13.2Zm1.122-2.69a1.553,1.553,0,0,1-.683.418v.339a.439.439,0,0,1-.879,0v-.714a.439.439,0,0,1,.439-.439.669.669,0,0,0,.668-.7.67.67,0,0,0-.654-.632H7.5a.668.668,0,0,0-.662.575.681.681,0,0,0-.007.094.439.439,0,0,1-.879,0,1.547,1.547,0,0,1,3.093-.082A1.535,1.535,0,0,1,8.622,10.508Z"
                                            fill="#8e8e8e"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <div className="option-text">
                                {trans({ id: "forget_pass", defaultMessage: "Forget Password" })}</div>
                        </div>
                        <div className="signup-option forget-user-option">
                            <svg
                                className="bord"
                                xmlns="http://www.w3.org/2000/svg"
                                width="350"
                                height="50"
                                viewBox="0 0 350 50"
                            >
                                <g
                                    id="Rectangle_4720"
                                    data-name="Rectangle 4720"
                                    fill="#f7f7f7"
                                    stroke="#ddd"
                                    strokeWidth="0.5"
                                >
                                    <rect width="350" height="50" rx="15" stroke="none" />
                                    <rect
                                        x="0.25"
                                        y="0.25"
                                        width="349.5"
                                        height="49.5"
                                        rx="14.75"
                                        fill="none"
                                    />
                                </g>
                            </svg>
                            <svg
                                id="_15x15_photo_back"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                            >
                                <g
                                    id="Group_7955"
                                    data-name="Group 7955"
                                    transform="translate(-735 -701)"
                                >
                                    <g
                                        id="_15x15_photo_back"
                                        data-name="15x15 photo back"
                                        transform="translate(735 701)"
                                    >
                                        <g id="Mask_Group_212" data-name="Mask Group 212">
                                            <g id="exchange">
                                                <g id="Group_7948" data-name="Group 7948">
                                                    <g id="Group_7947" data-name="Group 7947">
                                                        <path
                                                            id="Path_19229"
                                                            data-name="Path 19229"
                                                            d="M9.167,13.9A6.615,6.615,0,0,1,1.313,9.836a6.542,6.542,0,0,1-.1-4.385A6.66,6.66,0,0,1,3,2.654L2.849,3.9l.877.106.332-2.754L1.339.934l-.1.877,1.219.144A7.548,7.548,0,0,0,.373,5.176,7.5,7.5,0,0,0,9.39,14.757Z"
                                                            fill="#8e8e8e"
                                                        />
                                                    </g>
                                                </g>
                                                <g id="Group_7950" data-name="Group 7950">
                                                    <g id="Group_7949" data-name="Group 7949">
                                                        <path
                                                            id="Path_19230"
                                                            data-name="Path 19230"
                                                            d="M14.545,10.062A7.5,7.5,0,0,0,5.184.37l.274.84a6.611,6.611,0,0,1,6.107,11.5l.164-1.359-.877-.106L10.519,14l2.719.322.1-.877-1.115-.132A7.517,7.517,0,0,0,14.545,10.062Z"
                                                            fill="#8e8e8e"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <path
                                        id="Path_19237"
                                        data-name="Path 19237"
                                        d="M7.328,4.26a1.693,1.693,0,0,0-1.69,1.69v.4H5.118a.4.4,0,0,0-.4.4V9.81a.4.4,0,0,0,.4.4H9.539a.4.4,0,0,0,.4-.4V6.745a.4.4,0,0,0-.4-.4H9.018v-.4a1.693,1.693,0,0,0-1.69-1.69Zm.895,2.087H6.434v-.4a.895.895,0,1,1,1.789,0Z"
                                        transform="translate(735.172 701.24)"
                                        fill="#8e8e8e"
                                    />
                                </g>
                            </svg>

                            <div className="option-text">{trans({ id: "change_pass", defaultMessage: "Change Password" })}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Signup);
