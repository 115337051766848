import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyItem from './CurrencyItem'
import CurrencyModal from './CurrencyModal'

function CurrenciesList(props) {
  const dispatch = useDispatch()
  const requested_data = useSelector((state) => state.currency.request_data)

  const ActiveAdd = (e) => {
    dispatch({ type: "Active-add", payload: e })
  }
  const { lang_id, setLang_id } = props
  const { makeFollower } = props
  const currencies = useSelector(state => state.currency.currencies)
  const data = useSelector(state => state.currency.data)
  const mode = useSelector(state => state.currency.mode)
  const Active_Ids = useSelector(state => state.currency.Active_Ids)
  useEffect(() => {
    if (currencies.length === 0 && props.view === "Currencies") {
      dispatch({ type: "GET-CUR" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.view])
  return (
    <>
      {(lang_id === 0 || lang_id === null) && props.open && <CurrencyModal Active_Ids={Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { }} data={data} {...props} />}
      <div className='lang-body'>
        {
          currencies.map((dept) => (
            <CurrencyItem
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={props.open}
              Close={() => props.Close()}
              Add={(e) => props.Add(e)}
              Update={(e) => props.Update(e)}
              Read={(e) => props.Read(e)}
              setModalData={(e) => props.setModalData(e)}
              setOpen={(e) => props.setOpen(e)}
              child={dept}

              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
            />
          ))
        }
        {((props.open && mode === "add") || (requested_data && mode === "add")) && <CurrencyItem
          open={props.open}
          Close={() => props.Close()}
          Add={(e) => props.Add(e)}
          lang={props.lang}
          Update={(e) => props.Update(e)}
          new={"new"}
          Read={(e) => props.Read(e)}
          setOpen={(e) => props.setOpen(e)}
          child={requested_data ? requested_data : data}
          languageFollower={props.languageFollower}
          level={1}
          makeFollower={(level, value, id) => makeFollower(level, value, id)}
          lang_id={lang_id}
          setLang_id={(e) => setLang_id(e)}
        />}
      </div>
    </>
  )
}

export default CurrenciesList