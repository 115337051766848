import Langs from "../Routes/icons/svgs/Langs.svg"
import HRM from "../Routes/icons/svgs/HRM.svg"
import Countries from "../Routes/icons/svgs/Countries.svg"
import Currencies from "../Routes/icons/svgs/Currencies.svg"
import Shipment from "../Routes/icons/svgs/shipment.svg"
import Empty from "../Routes/icons/svgs/empty.svg"
export const dashboardOptions = [
    {
        id: 0,
        name: "Work Desk",
        key: "work_desk",
        icon: null,
        add: null,
        childreen: [
            //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
            { id: 8, name: "Shipments", key: "shipments", routes: "full-content", add: true, icon: Shipment, childreen: [] },
            { id: 9, name: "Empty", key: "empty", routes: "full-content", add: true, icon: Empty, childreen: [] }
        ]
    },
    {
        id: 1,
        name: "System",
        key: "system",
        icon: null,
        add: null,
        childreen: [
            //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
            { id: 3, name: "Languages", key: "languages", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 4, name: "Translate", key: "translate", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 5, name: "Countries", key: "countries", routes: "full-content", add: true, icon: Countries, childreen: [] },
            { id: 6, name: "Currencies", key: "currencies", routes: "full-content", add: true, icon: Currencies, childreen: [] }
        ]
    },
    {
        id: 2,
        name: "Management",
        key: "management",
        icon: null,
        add: null,
        childreen: [{ id: 7, name: "HRM", key: "hrm", routes: "HRM", add: true, icon: HRM, childreen: [] },
        ]
    }
    // {id:2,
    //     name:"System",
    //     icon:null,
    //     add:null,
    //     childreen:[
    //     //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:12, name:"Translate",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:2, name:"Countries",  routes:"full-content", add:true, icon:Countries, childreen:[]},
    //     {id:2, name:"Currencies",  routes:"full-content", add:true, icon:Currencies, childreen:[]},]
    // },
    // {id:3,
    //     name:"Management",
    //     icon:null,
    //     add:null,
    //     childreen:[{id:1, name:"HRM",  routes:"HRM", add:true, icon:HRM, childreen:[]},

    // ]
    //     }
]
export const langsdata = [
    { id: 1, name: "Arabic", icon: "AR", childs: [{ id: 11, name: "Syria", icon: "SY", childs: [{ id: 111, name: "Damascus", icon: "DA", childs: [] }, { id: 112, name: "Aleppo", icon: "AL", childs: [] }, { id: 113, name: "Latakia", icon: "LA", childs: [] }] }, { id: 12, name: "Iraq", icon: "IQ", childs: [] }, { id: 12, name: "Lebanon", icon: "LB", childs: [] }] },
    { id: 2, name: "Turkish", icon: "TR", childs: [] },
    { id: 3, name: "English", icon: "EN", childs: [] }
]
export const GetTransValidate = (arr) => {
    let bool = true
    arr.forEach((name) => {
        if (name.name.length === 0)
            bool = false
    })
    return bool
}