import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExpandICon from '../icons/ExpandICon'
import LangChildreen from './LangChildreen'
import Empty from "../../../../assets/svg/emptyIcon.svg"
import LanguagesModal from "./LanguagesModal"
import SmallIcon from '../../../../assets/svg/SmallIcon'
import LoadingStatuse from '../../LoadingStatuse'
import ActiveIcon from './ActiveIcon'
import { trans, showTrans } from '../../../../Utils'
function LanguagetItem(props) {
  const getName = () => {
    if (child.translations.filter((s) => s.language_code === lang).length > 0) {
      return child.translations.filter((s) => s.language_code === lang)[0].name
    }
    else return child.translations.filter((s) => s.language_code === "en")[0].name
  }
  const mode = useSelector((state) => state.langs.mode)
  const lang = useSelector((state) => state.auth.lang)
  const request_running = useSelector((state) => state.langs.request_running)
  const request_data = useSelector((state) => state.langs.request_data)
  const error = useSelector((state) => state.langs.error)
  const message = useSelector((state) => state.langs.message)
  const sucess_message = useSelector((state) => state.langs.sucess_message)

  useEffect(() => {
    if (!props.languageFollower.filter((e) => e.name === getName()).length > 0) {
      setExpand(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.languageFollower])
  const { child, lang_id, setLang_id } = props
  const [expand, setExpand] = useState(lang_id === child.language_code)
  const data = useSelector(state => state.langs.newLang)
  const dispatch = useDispatch()
  useEffect(() => {
    if (mode === "add" && lang_id === child.language_code && props.open && child.language_code.length > 0) {

      setExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang_id, props.open])
  useEffect(() => {

  }, [props.languageFollower])
  return (
    <>
      <div level={props.level} className={`${props.languageFollower.length > 0 && props.languageFollower.filter((s) => s.name === getName()).length > 0 && "activated"} parent-info ${expand && "expand-line"} ${(props.open) && "disable-hover"} ${props.accent}`} >
        {(child.son_languages && child.son_languages.length > 0) &&
          <ExpandICon setExpand={() => { setLang_id(child.language_code); setExpand(!expand); props.makeFollower(props.level, getName(), child.language_code); }} expand={expand} />
        }
        {!(request_data && request_data.language_code === child.language_code) && (child.parent_language_code === null) && <ActiveIcon setActive={() => { if (parseInt(child.is_used_in_system) === 0) dispatch({ type: "SET-LANGUAGE", payload: { child: child, value: 0 } }); else dispatch({ type: "SET-LANGUAGE", payload: { child: child, value: 1 } }) }} active={parseInt(child.is_used_in_system) === 1} />}
        <LoadingStatuse success_message={sucess_message} child={child} request_data={request_data} request_running={request_running} error={error} message={message} />
        {child.flat_photo_path && child.flat_photo_path.length > 3 ?
          <div onClick={() => { setExpand(!expand); props.makeFollower(props.level, getName(), child.language_code); setLang_id(child.language_code); }} className={`${((request_running || error) && (request_data.language_code === child.language_code) && "pending")} parent-icon flat `}>
            <svg crossorigin data-src={process.env.REACT_APP_BASE_FILE_URL + child.flat_photo_path} width="15" height="15"></svg>
          </div>
          : <>{child.language_code.length > 0 ?
            <>
              <div className={`${((request_running || error) && (request_data.language_code === child.language_code) && "pending")} parent-icon ${!child.parent_language_code && "language-icon-gray"} ${props.new}`}>
                <SmallIcon icon={child.language_code.toUpperCase()} />
              </div>
            </> :
            <div className={`${((request_running || error) && (request_data.language_code === child.language_code) && "pending")} parent-icon pu ${props.new}`}>
              <img alt={getName()} width={"20px"} height="20px" src={Empty} />
            </div>}</>
        }
        < div className='parent-row'>
          <div onClick={() => { props.makeFollower(props.level, getName(), child.language_code); setLang_id(child.language_code); props.Read(child) }} className={`${((request_running || error) && (request_data.language_code === child.language_code) && "pending")} parent-name ${lang_id === child.language_code && "active-lang"}`}>{showTrans(child.translations, props.lang)}</div>
          <div className={`icon-langs ${props.new}`}>
            <div className='icon-lang' onClick={() => { props.makeFollower(props.level, getName(), child.language_code); setLang_id(child.language_code); props.Add(child); setExpand(true); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu'>{trans({ id: "add_accent", defaultMessage: "Add New Accent" })}</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
                  <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                </g>
              </svg>
            </div>
            <div className='icon-lang' onClick={() => { props.makeFollower(props.level, getName(), child.language_code); setLang_id(child.language_code); props.Update(child); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu'> {trans({ id: "edit_lang", defaultMessage: "Edit Language" })}</div>
              </div>
              <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g data-name="Mask Group 65" >
                  <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                    <g id="user" transform="translate(0.258)">
                      <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                      <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                      <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                      <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                      <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                    </g>
                    <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        {(expand && child.son_languages) && (<LangChildreen lang={props.lang} setModalData={(e) => props.setModalData(e)} Active_Ids={props.Active_Ids} ActiveAdd={(e) => props.ActiveAdd(e)} setExpand={(e) => { setExpand(e) }} languageFollower={props.languageFollower} setLang_id={(e) => setLang_id(e)} {...props} level={props.level + 1} makeFollower={(e, w, id) => props.makeFollower(e, w, id)} child={child} />)}
      </div>
      {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
      {props.open && ((lang_id === child.language_code)) && <LanguagesModal Active_Ids={props.Active_Ids} ActiveAdd={() => props.ActiveAdd(data.language_code)} languageFollower={props.languageFollower} setExpand={(e) => { setExpand(e) }} data={data} {...props} />}

      {/* {SelectedLanguage.name.length>0&&    <DetailsModal languageFollower={props.languageFollower}  edit={edit} setEdit={(e)=>setEdit(e)}  lang={SelectedLanguage} setLang={(e)=>{setAccent(e); setLang(e)}}  update={()=>{selectLanguage(child)}} setExpand={(e)=>setExpand(e)} setopenAccent={(e)=>setopenAccent(e)} child={SelectedLanguage} open={detailsModal} setOpen={(e)=>setDetailModal(e)}/>} */}
    </>
  )
}

export default LanguagetItem