import PackageCart from "./svg/package_cart"

export default function PackageFilter(props) {
    const filters = props.data
    return (<div className="ship_package_filter">
        {filters.map((filter, index) => <div key={index} className={`ship_package_filter_child`} onClick={() => {
            if (props.selected === filter.id) {
                props.setFilter(null)
            } else {
                props.setFilter(filter.id)
            }
        }}>
            <div className="ship_package_filter_child_icon">
                <PackageCart color1={filter.color1} color2={filter.color2} />
            </div>
            <div className={`${props.selected === filter.id ? "ship_package_filter_child_count_selected" : "ship_package_filter_child_count"}`}>{filter.pallets_count}</div>
        </div>)}
    </div>)
}