import React, { useEffect, useState } from 'react'
import Curre from "../../../../assets/svg/Langs.svg"
import { useSelector, useDispatch } from "react-redux"
import LangBody from './LangBody'
import "react-toastify/dist/ReactToastify.min.css"
import { trans } from '../../../../Utils'
import { TopNav } from "../shared/index"

function LinkedLanguages(props) {
  const [languageFollower, setLanguageFollower] = useState([])
  const [lang_id, setLang_id] = useState(null)
  const dispatch = useDispatch()
  const [lang, setLang] = useState(localStorage.getItem("APP_LANG"))
  const translated_lang = useSelector((state) => state.auth.lang)
  const getLang = () => {
    if (lang) {
      return (lang)
    }
    else {
      return translated_lang
    }
  }
  useEffect(() => {

  }, [languageFollower])
  const makeFollower = (level, value, id) => {

    if (id === 0) {

      setLang_id(null)

    }
    else setLang_id(id)
    if (languageFollower.length >= level) {
      let arr = languageFollower
      arr[level] = { name: value, id: id }
      arr = arr.filter((a, index) => index <= level)
      setLanguageFollower([...arr])

    } else {
      setLanguageFollower([...languageFollower, { name: value, id: id }])
    }
  }

  const languages = useSelector(state => state.langs.languages)
  const open = useSelector(state => state.langs.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-LANG", payload: e })
  }
  const setMode = (e) => {
    dispatch({ type: "MODE-LANG", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-LANG", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-LANG", payload: { ...e } })

  }
  const Update = (e) => {
    dispatch({ type: "request-running-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null, language_code: "" }
    if (e.translations.length !== languages.length) {

      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
    }
    else {
      obj.translations = [...e.translations]
    }
    setData({ ...e, translations: [...obj.translations] });
    setModalData({ name: e.language_code + " ", icon: null, code: "Department" });
    setMode("update");
    setOpen(true);
  }
  const Read = (e) => {

    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }
    setModalData({ name: e.translations.filter(t => t.language_code === "en").name, icon: e.icon, code: e.language_code })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    let obj = { translations: [], icon: null, language_code: "", flat_photo_path: "", outline_photo_path: "", fill_photo_path: "", name_in_native_language: "" }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })

  }
  const Add = (e) => {
    setOpen(false);
    dispatch({ type: "request-running-red", paylod: null })
    let obj = { translations: [], icon: null, language_code: "", flat_photo_path: "", outline_photo_path: "", fill_photo_path: "", name_in_native_language: "", parent_language_code: null }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    if (e) {
      obj.parent_language_code = e.language_code
      setModalData({ name: e.language_code + " Language", icon: null, language_code: "Language" });
    }
    else {
      if (languageFollower.length === 0) {
        obj.parent_language_code = null
      }
      else obj.parent_language_code = languageFollower[languageFollower.length - 1].id?.toLowerCase()
      setModalData({ name: "Language", icon: null, language_code: "Language" })
    }
    setData(obj);

    setMode("add");
    setTimeout(() => {
      setOpen(true);
    }, 100);

  }
  return (
    <>
      <div className={`languages-page ${props.active && "Active-page"}`}>
        <TopNav
          table_icon={Curre}
          top_name_clk={() => {
            setLang_id(0);
            setLanguageFollower([])
          }}
          table_name={trans({ id: "languages", defaultMessage: "Languages" })}
          follower={languageFollower}
          followerclk={(index, name, id) => {
            makeFollower(index, name, id)
          }}
          setLang={(e) => setLang(e)}
          disableAdd={!languages.length > 0}
          add_button_clk={() => {
            if (languages.length > 0 && languageFollower.length === 0) Add();
            if (languageFollower.length > 0 && languageFollower[languageFollower.length - 1].id)
              setLang_id(languageFollower[languageFollower.length - 1].id)
          }}
          haveView={false}
        />
        <LangBody lang={getLang()} setModalData={(e) => setModalData(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e) => setOpen(e)} Add={(e) => Add(e)} Close={(e) => Close(e)} Update={(e) => Update(e)} Read={(e) => Read(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      </div >
    </>
  )
}

export default LinkedLanguages