import PackageCart from "./svg/package_cart"

export default function PackageFilterExpand(props) {
    const filters = props.data
    return (<div className="ship_package_filter_expand">
        {filters.map((filter, index) => <div key={index} className="node_parent_expand">
            <div className="ship_package_filter_child_expand">
                <div className="ship_package_filter_child_icon_expand">
                    <PackageCart color1={filter.color1} color2={filter.color2} />
                </div>
                <div className="ship_package_filter_child_count_expand">{filter.pallets_count}</div>
                <div className="ship_package_filter_child_name_expand">{filter.translations[0]?.name}</div>
            </div>
        </div>)}
    </div>)
}