import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ExpandICon from '../../icons/ExpandICon'
import EmpChildreen from './EmpChildreen'
import Empty from "../../icons/svgs/emptyIcon.svg"
import manager from "../../../../../assets/svg/manager.svg"
import EmployeeModal from './EmployeeModal'
import LoadingStatuse from './LoadingStatuse'

import { trans, showTrans } from '../../../../../Utils'
function EmployeeItem(props) {
  const { child, lang_id, setLang_id } = props
  const mode = useSelector((state) => state.employee.mode)
  const [expand, setExpand] = useState(lang_id === child.id)
  const getName = () => {
    if (props.child.employees) {
      return (child.translations?.length > 0 && showTrans(child.translations, localStorage.getItem("APP_LANG")))
    }
    else {
      return (child.full_name)
    }
  }
  useEffect(() => {
    if (!props.languageFollower.filter((e) => e.name === getName())?.length > 0) {
      setExpand(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.languageFollower])


  const data = useSelector(state => state.employee.newDepartment)
  useEffect(() => {
    if (mode === "add" && lang_id === child.id && props.open && getName()?.length > 0 && getName()) {
      props.setModalData({ name: child.code + " Employee", icon: child.avatar, code: getName() })
      setExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang_id, props.open])
  const request_running = useSelector((state) => state.employee.request_running)
  const request_data = useSelector((state) => state.employee.request_data)
  const error = useSelector((state) => state.employee.error)
  const message = useSelector((state) => state.employee.message)

  return (
    <>

      <div level={props.level} className={`${props.languageFollower && props.languageFollower.filter((s) => s.name === getName()).length > 0 && "activated"} dept parent-info ${expand && "expand-line"} ${(props.open) && "disable-hover"} ${props.accent}`} >
        {((child.employees && child.employees?.length > 0) || ((child.son_departments && child.son_departments?.length > 0))) &&
          <ExpandICon setExpand={() => { setLang_id(child.id); setExpand(!expand); props.makeFollower(props.level, getName()); }} expand={expand} />
        }
        {!child.employees && <LoadingStatuse child={child} request_data={request_data} request_running={request_running} error={error} message={message} />}
        {<div onClick={() => { setExpand(!expand); props.makeFollower(props.level, getName(), child.id); setLang_id(child.id); }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-icon pure outline ${lang_id === child.id && "active-lang"}`}>
          {!child.employees && <svg data-src={manager} width="15" height="15"></svg>}
          {(child.flat_photo_path && child.flat_photo_path?.length > 0) ?
            <>
              {(!child.parent_department_id) ?
                <>
                  <svg data-src={process.env.REACT_APP_BASE_FILE_URL + child.flat_photo_path} width="15" height="15"></svg>
                </>
                :
                <>
                  <svg className='dept-child' xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                    <g id="_15x23_flag_photo" data-name="15x23 flag photo">
                      <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#ddd" />
                    </g>
                    <text textAnchor="middle" id="CFO" transform="translate(0 2)" fill="#404040" fontSize="8" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="50%" y="9">{child.translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].short_name.slice(0, 3)}</tspan></text>
                  </svg>


                </>
              }
            </>
            :
            // empty
            <>
              {child.translations && child.parent_department_id ?
                child?.translations?.filter((w) => w.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0]?.short_name?.length > 0 ? <svg className='dept-child' xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo">
                    <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#ddd" />
                  </g>
                  <text textAnchor="middle" id="CFO" transform="translate(0 2)" fill="#404040" fontSize="8" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="50%" y="9">{child.translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].short_name.slice(0, 3)}</tspan></text>
                </svg> : <svg data-src={Empty} width="23" height="15"></svg>
                : child.translations && <svg data-src={Empty} width="15" height="15"></svg>}
            </>
          }

        </div>


        }

        < div className='parent-row'>
          <div onClick={() => { props.makeFollower(props.level, getName()); setLang_id(child.id); props.Read(child) }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-name ${lang_id === child.id && "active-lang"}`}>{getName()}</div>
          {child.employees && <div className='num-icon'>{child.employees?.length}</div>}
          <div className={`icon-langs ${props.new}`}>

            {!child.employees && <div className='icon-lang' onClick={() => { props.makeFollower(props.level, getName(), child.id); setLang_id(child.id); props.Update(child); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu'>{trans({ id: "edit_emp", defaultMessage: "Edit Employee" })}</div>
              </div>
              <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g data-name="Mask Group 65" >
                  <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                    <g id="user" transform="translate(0.258)">
                      <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                      <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                      <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                      <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                      <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                    </g>
                    <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                  </g>
                </g>
              </svg>
            </div>}
            {child.employees && <div className='icon-lang' onClick={() => { props.makeFollower(props.level, getName(), child.id); setLang_id(child.id); props.Add(child); setExpand(true); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu' style={{ width: "91px" }}>{trans({ id: "add_emp", defaultMessage: "Add Employee" })}</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
                  <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                </g>
              </svg>
            </div>}
          </div>
        </div>
        {((expand && child.son_departments) || (expand && child.employees)) && (<EmpChildreen lang={props.lang} setModalData={(e) => props.setModalData(e)} Active_Ids={props.Active_Ids} ActiveAdd={(e) => props.ActiveAdd(e)} setExpand={(e) => { setExpand(e) }} languageFollower={props.languageFollower} setLang_id={(e) => setLang_id(e)} {...props} level={props.level + 1} makeFollower={(e, w, id) => props.makeFollower(e, w, id)} child={child} />)}
      </div>
      {props.open && ((lang_id === child.id)) && <EmployeeModal Active_Ids={props.Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { setExpand(e) }} data={data} {...props} />}

    </>
  )
}

export default EmployeeItem