import { withRouter } from "react-router-dom"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import avat from "../../../assets/svg/avat.png";
import gear from "../../../assets/svg/gear.svg";
import ChatIcon from "../../../assets/svg/chat"
import { _changeAppLang, _getAppLang } from "../../../Utils"
import Sidebar from './Sidebar'
import { dashboardOptions } from "./constants"
function MainPage(props) {
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const viewedList = useSelector(state => state.categories.viewedList)
    const pass = useSelector((state) => state.auth.pass)
    const regions = useSelector(state => state.countries?.countries)
    const roles = useSelector(state => state.auth.roles)
    const employees = useSelector(state => state.employee.employee)

    const [language, setLanguage] = useState(_getAppLang())
    const [ActiveLink, setActiveLink] = useState(null)
    const [foc, setFocue] = useState(false)
    useEffect(() => {
        if (!token) {
            props.history.push("/auth/signup")
        }
        // eslint-disable-next-line
    }, [token])
    useEffect(() => {
        if (
            localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).id &&
            pass
        ) {
            if (roles.length === 0) {
                dispatch({ type: "GET_AUTH_ROLES" })
            }
            if (regions?.length === 0) {
                dispatch({ type: "REGION_ASYNC_SAGA" });
            }
            if (employees?.length === 0) {
                dispatch({ type: "GET-EMP" });
            }
        } else {
            props.history.push("/auth/signup");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getStyle = () => {
        let a = window.pageYOffset || document.documentElement.scrollTop;
        // 
        if (a === 0) return { position: "absolute", zIndex: 99 };
        else return { position: "fixed", zIndex: "99999" };
    }
    const setPass = (a) => {
        dispatch({ type: "PASS", payload: a });
    };
    const getTime = (d) => {
        const a = new Date();
        if (!d)
            return `${(a.getHours() > 9
                ? a.getHours().toString()
                : "0" + a.getHours().toString()) +
                ":" +
                (a.getMinutes() > 9
                    ? a.getMinutes().toString()
                    : "0" + a.getMinutes().toString())
                }`;
        else return `${localStorage.getItem("session-start")}`;
    }
    useEffect(() => {
        let bool = true
        if (!props.match.params.route) {
            if (bool)
                setActiveLink(null);
        } else if (props.match.params.route?.toLowerCase() === "categories") {
            dashboardOptions.forEach((m) => {
                m.childreen.forEach((s) => {
                    if (s.name?.toLowerCase() === props.match.params.route?.toLowerCase()) {
                        if (bool)
                            setActiveLink(s);
                    }
                });
            });
        } else {
            dashboardOptions.forEach((m) => {
                m.childreen.forEach((s) => {
                    if (s.name?.toLowerCase() === props.match.params.route?.toLowerCase()) {
                        if (bool)
                            setActiveLink(s);
                    }
                });
            });
        }
        return () => (bool = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params]);
    const AddLayout = (laoyout, level, element) => {
        let res = [];
        if (
            element !== null &&
            !element.target.classList.contains("plus-con") &&
            !element.target.classList.contains("disable_click")
        ) {
            if (viewedList.filter((f) => f.name === laoyout.name).length > 0) {
                res = viewedList.filter((k, i) => i < level)
            } else {
                res = viewedList.filter((k, i) => i < level)

                if (res.length === 0) res.push(laoyout)
                else res[level] = laoyout;
            }
            dispatch({ type: "ADD_LAYOUT", layout: res })
        }
    }
    return (<div className="main">
        {/* {isCallIncoming && <CallComponent reply={() => setWA(true)} />} */}
        {/* {!navigator.onLine && <div className="off_label">
        <img alt="" className="off_img" src={off} />
        <span>You are Offline</span></div>} */}
        {/* {WAP && <Whatsapp open={WAP} close={() => setWA(false)} />} */}
        {/* {openSetting && <Setting open={openSetting} close={() => setOpenSetting(false)} />} */}
        {/* {localStorage.getItem("user") && (
        <ChatWidget open={chat} openChat={(e) => openChat(e)} />
      )} */}
        <div
            className={`avatar-account  ${foc && "active-h"}`}
            onMouseLeave={() => setFocue(false)}
        >
            <span className="abs-span" onMouseEnter={() => setFocue(true)}></span>
            {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).avatar &&
                JSON.parse(localStorage.getItem("user")).avatar.length > 0 ? (
                <img alt=""
                    onMouseEnter={() => setFocue(true)}
                    src={process.env.REACT_APP_BASE_FILE_URL + JSON.parse(localStorage.getItem("user")).avatar}
                />
            ) : (
                <img alt="" onMouseEnter={() => setFocue(true)} src={avat} />
            )}

            <div className="time-info">
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                    >
                        <g id="Mask_Group_178" data-name="Mask Group 178">
                            <g id="deadline" transform="translate(0.636 0)">
                                <path
                                    id="Path_19125"
                                    data-name="Path 19125"
                                    d="M11.747,5.209a2,2,0,0,0,.311-1.324.778.778,0,0,0-.4-.5.7.7,0,0,0-.556-.052,1.415,1.415,0,0,0-.73.813,5.917,5.917,0,0,0-3.2-.77l-.083-.8c.1-.019.2-.04.319-.059a4.4,4.4,0,0,0,1.365-.4,1.115,1.115,0,0,0,.216-.162A.761.761,0,0,0,9.237,1.3,1.084,1.084,0,0,0,8.7.518,2.041,2.041,0,0,0,7.289.426L4.86.9c-.062.012-.131.024-.2.038-.742.133-1.98.357-1.852,1.472a.873.873,0,0,0,.376.654,1.159,1.159,0,0,0,.644.169,2.565,2.565,0,0,0,.7-.112c.271-.081.544-.143.825-.2l.155.768a5.936,5.936,0,0,0-2.465,1.6A.252.252,0,0,0,2.965,5.2a2.306,2.306,0,0,0-1.208-.333.766.766,0,0,0-.72.366.624.624,0,0,0,.1.661,2.052,2.052,0,0,0,.758.449c.1.045.2.093.288.136a5.986,5.986,0,0,0,5.231,8.87,6.157,6.157,0,0,0,1.194-.119A5.99,5.99,0,0,0,11.747,5.209Zm-.495-1.434a.222.222,0,0,1,.19.024.3.3,0,0,1,.164.2,1.452,1.452,0,0,1-.209.873,5.752,5.752,0,0,0-.63-.485.143.143,0,0,0,.021-.031,1.057,1.057,0,0,1,.464-.585ZM4.4,2.672a1.26,1.26,0,0,1-.958,0,.407.407,0,0,1-.169-.319C3.2,1.728,3.894,1.55,4.741,1.4l.207-.038L7.377.884A3.224,3.224,0,0,1,7.969.815a1,1,0,0,1,.492.107.631.631,0,0,1,.311.433.3.3,0,0,1-.1.264.772.772,0,0,1-.133.1,4.049,4.049,0,0,1-1.21.34c-.162.029-.311.057-.437.086-.326.076-.663.138-.991.2a14.094,14.094,0,0,0-1.5.333Zm1.415.157L5.982,2.8c.214-.04.43-.081.649-.126l.078.742c-.155.019-.309.043-.464.074-.1.019-.195.043-.29.067ZM2.09,5.915a2.886,2.886,0,0,1-.575-.3c-.055-.074-.076-.138-.057-.174s.131-.107.3-.107a1.861,1.861,0,0,1,.963.264.174.174,0,0,0,.045.017c-.119.15-.233.3-.338.464-.1-.05-.214-.1-.34-.159Zm6.429,8.854A5.52,5.52,0,1,1,6.338,3.947a5.575,5.575,0,0,1,1.1-.109A5.52,5.52,0,0,1,8.519,14.769Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19126"
                                    data-name="Path 19126"
                                    d="M9.591,5.7a.235.235,0,0,0-.319.083L7.663,8.55a.851.851,0,0,0-.43-.024.882.882,0,0,0,.174,1.747.859.859,0,0,0,.174-.017.882.882,0,0,0,.692-1.039A.86.86,0,0,0,8.057,8.8L9.676,6.022A.235.235,0,0,0,9.591,5.7Zm-2.1,4.1a.416.416,0,1,1-.164-.815.492.492,0,0,1,.083-.007.415.415,0,0,1,.228.069.415.415,0,0,1-.147.754Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19127"
                                    data-name="Path 19127"
                                    d="M5.825,12.86A3.888,3.888,0,0,1,3.488,8.6,4.288,4.288,0,0,1,7.251,5.135a.233.233,0,1,0-.05-.464A4.755,4.755,0,0,0,3.029,8.514a4.387,4.387,0,0,0,2.589,4.762.232.232,0,0,0,.1.024.231.231,0,0,0,.1-.44Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#215bf8"
                                />
                                <path
                                    id="Path_19128"
                                    data-name="Path 19128"
                                    d="M13.8,7.71a4.783,4.783,0,0,0,.04,1.1A4.956,4.956,0,0,0,13.977,9.9,3.064,3.064,0,0,0,14.008,8.8,3.066,3.066,0,0,0,13.8,7.71Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19129"
                                    data-name="Path 19129"
                                    d="M14.7,9.082a2.968,2.968,0,0,0-.221-1.213,9.915,9.915,0,0,0,.055,1.217,10.435,10.435,0,0,0,.033,1.217A2.953,2.953,0,0,0,14.7,9.082Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19130"
                                    data-name="Path 19130"
                                    d="M1.833,13.164c-.045,0-.043.262.136.511s.423.335.44.295-.152-.176-.3-.392-.223-.421-.271-.414Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19131"
                                    data-name="Path 19131"
                                    d="M1.327,13.124a1.349,1.349,0,0,0,.152.92,1.339,1.339,0,0,0,.6.713c.033-.036-.247-.333-.452-.782s-.252-.854-.3-.851Z"
                                    transform="translate(-0.982 -0.347)"
                                    fill="#404040"
                                />
                            </g>
                        </g>
                    </svg>
                </span>
                <div className="times-in">
                    <div className="time-hour">{getTime()}</div>
                    <div className="times-work">
                        <span className="sem">8</span> h {6000 < 3600 ?
                            <>
                                <span className="sem"> / {parseInt(6000 / 60)} </span> m
                            </>
                            :
                            <>
                                <span className="sem">
                                    {" "}
                                    / {parseFloat(6000 / 60 / 60).toFixed(2)}{" "}
                                </span>{" "}
                                h
                            </>
                        }
                    </div>
                </div>
                <div className="times-in out-time">
                    <div className="time-hour">{getTime(true)}</div>
                    <div className="times-work">
                        <span className="sem">8</span> h
                        <>
                            <span className="sem">
                                {" "}
                                / {parseFloat(6000 / 60 / 60 - 8).toFixed(2)}{" "}
                            </span>{" "}
                            h
                        </>
                    </div>
                </div>
            </div>
            {
                <div className="account-options">
                    <svg
                        onClick={() => {
                            // logout(() => { ; })
                            dispatch({ type: "log_out" })
                            localStorage.removeItem("user");
                            window.location.reload()
                        }}
                        id="_15x15_photo_back"
                        data-name="15x15 photo back"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                    >
                        <g id="Mask_Group_179" data-name="Mask Group 179">
                            <g id="layer1" transform="translate(0 0)">
                                <path
                                    id="path52"
                                    d="M8.992,825.387a.752.752,0,0,0-.743.762v6.029a.751.751,0,1,0,1.5,0v-6.029a.752.752,0,0,0-.759-.762Zm4.376,1.511c-.024,0-.049,0-.073,0a.754.754,0,0,0-.416,1.33,6,6,0,1,1-7.779.022.756.756,0,0,0,.084-1.062.748.748,0,0,0-1.058-.084A7.556,7.556,0,0,0,9,840.387a7.556,7.556,0,0,0,4.845-13.3.749.749,0,0,0-.481-.185Z"
                                    transform="translate(-1.5 -825.387)"
                                    fill="#f80000"
                                />
                            </g>
                        </g>
                    </svg>
                    <svg
                        onClick={() => {
                            setPass(false);
                            dispatch({ type: "log_out" })
                            props.history.push("/auth/signup");
                        }}
                        id="_15x15_photo_back"
                        data-name="15x15 photo back"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                    >
                        <g id="Mask_Group_180" data-name="Mask Group 180">
                            <g id="padlock" transform="translate(1.875)">
                                <g id="Group_7828" data-name="Group 7828">
                                    <path
                                        id="Path_19132"
                                        data-name="Path 19132"
                                        d="M12.188,6.563V4.688a4.219,4.219,0,0,0-8.437,0V6.563A1.381,1.381,0,0,0,2.344,7.969v6.094A1.41,1.41,0,0,0,3.75,15.469h8.438a1.41,1.41,0,0,0,1.406-1.406V7.969A1.381,1.381,0,0,0,12.188,6.563Zm-7.5-1.875a3.281,3.281,0,0,1,6.563,0V6.563H4.688Zm3.75,6.938v1.031a.469.469,0,0,1-.937,0V11.625a1.365,1.365,0,0,1-.937-1.312,1.406,1.406,0,0,1,2.813,0A1.365,1.365,0,0,1,8.438,11.625Z"
                                        transform="translate(-2.344 -0.469)"
                                        fill="#404040"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg
                        className={`${language === "ar" && "active-language"}`}
                        onClick={() => {
                            setLanguage("ar")
                            _changeAppLang("ar")
                            dispatch({ type: "TRANSLATE", payload: "ar" })
                        }}
                        id="Group_4725"
                        data-name="Group 4725"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                    >
                        <path
                            id="Exclusion_1"
                            data-name="Exclusion 1"
                            d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM9.162,8.17h1.559l1.3,2.4a.4.4,0,0,0,.4.262.387.387,0,0,0,.415-.374.552.552,0,0,0-.085-.284L11.561,8.044a1.854,1.854,0,0,0,1.309-1.87,1.886,1.886,0,0,0-2.077-1.941H8.756a.387.387,0,0,0-.407.419v5.759a.407.407,0,1,0,.813,0V8.171Zm-5.654.714H6.087L6.665,10.5a.389.389,0,0,0,.4.311.371.371,0,0,0,.406-.356.665.665,0,0,0-.049-.226l-2.1-5.673a.511.511,0,0,0-.51-.389.525.525,0,0,0-.533.393L2.18,10.23a.665.665,0,0,0-.049.226.371.371,0,0,0,.407.356.387.387,0,0,0,.4-.311l.574-1.617Zm2.348-.69H3.742L4.786,5.222h.027l1.043,2.97Zm4.865-.741H9.162v-2.5h1.522A1.194,1.194,0,0,1,12.03,6.2,1.166,1.166,0,0,1,10.721,7.453Z"
                            transform="translate(0 0)"
                            fill="#8e8e8e"
                        />
                    </svg>
                    <svg
                        className={`${language === "en" && "active-language"}`}
                        onClick={() => {
                            setLanguage("en")
                            dispatch({ type: "TRANSLATE", payload: "en" })
                            _changeAppLang("en")
                        }}
                        id="Group_4727"
                        data-name="Group 4727"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                    >
                        <path
                            id="Exclusion_4"
                            data-name="Exclusion 4"
                            d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM8.286,5.582h.042l3.572,5.1a.506.506,0,0,0,.49.278.433.433,0,0,0,.48-.462V4.476a.419.419,0,1,0-.838,0V9.437H11.99L8.422,4.321a.514.514,0,0,0-.49-.283c-.309,0-.479.155-.479.437v5.992a.439.439,0,0,0,.413.494.4.4,0,0,0,.419-.438V5.583ZM2.554,4.047a.4.4,0,0,0-.424.438V10.4a.4.4,0,0,0,.424.438H6a.382.382,0,1,0,0-.762H2.977V7.743H5.835a.372.372,0,0,0,.4-.377.368.368,0,0,0-.4-.376H2.977V4.811H6a.382.382,0,1,0,0-.763Z"
                            transform="translate(0 0)"
                            fill="#8e8e8e"
                        />
                    </svg>
                </div>
            }
        </div>
        <div className="account-cont" style={getStyle()}>
            <div className="lang-top account-top">
                <div className={`top-account-icons`}>
                    {(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).role_id === 1) && <svg onClick={() => { }} style={{ marginRight: "10px", height: "22px", fill: true ? "#5d5d5d" : "#8e8e8e" }} data-src={gear}></svg>}
                    {/* <div className="chat-icon" style={{ marginRight: "15px" }}>

                        <div
                            className="chat-top-icons wa-icons"
                            style={{
                                left: "10px",
                            }}
                        >
                        </div>
                        <svg onClick={() => { }} data-src={wa} ></svg>
                    </div> */}
                    <svg
                        style={{ marginRight: "20px" }}
                        id="_20X20_photo_Back"
                        data-name="20X20 photo Back"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                    >
                        <g id="Mask_Group_218" data-name="Mask Group 218">
                            <g id="bell">
                                <path
                                    id="Path_19249"
                                    data-name="Path 19249"
                                    d="M17.816,14.094a5.582,5.582,0,0,1-1.982-4.271V7.5a5.838,5.838,0,0,0-5-5.767v-.9a.833.833,0,0,0-1.667,0v.9a5.837,5.837,0,0,0-5,5.767V9.823A5.588,5.588,0,0,1,2.177,14.1a1.458,1.458,0,0,0,.948,2.566h13.75a1.458,1.458,0,0,0,.941-2.572Z"
                                    fill="#8e8e8e"
                                />
                                <path
                                    id="Path_19250"
                                    data-name="Path 19250"
                                    d="M10,20a3.13,3.13,0,0,0,3.062-2.5H6.938A3.13,3.13,0,0,0,10,20Z"
                                    fill="#8e8e8e"
                                />
                            </g>
                        </g>
                    </svg>
                    <ChatIcon />
                </div>
            </div>
        </div>
        <Sidebar
            foc={foc}
            ActiveLink={ActiveLink}
            setActiveLink={(e) => setActiveLink(e)}
            viewedList={viewedList}
            AddLayout={(laoyout, level, element) =>
                AddLayout(laoyout, level, element)
            }
        />
    </div>)
}
export default withRouter(MainPage)